import axios from "axios";

export async function getProducts(input, type) {
  const body = { [type]: input };
  return axios
    .post(`/api/product/${type}`, body)
    .then((res) => {
      return res.data.status;
    })
    .catch((err) => {
      console.log(err.response.statusText);
    });
}
