import React, { useState } from "react";
import styles from "./Searching.module.css";
import axios from "axios";
import { TextField } from "@material-ui/core";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Button } from "@material-ui/core";

/**
 *
 * @param {*} input value being searched
 * @param {*} type upc or name of product
 * @returns a promise that there will be data, else error
 */
async function getProducts(input, type) {
  const body = { [type]: input };
  return axios
    .post(`/api/product/${type}`, body)
    .then((res) => {
      return res.data.status;
    })
    .catch((err) => {
      console.log(err.response.statusText);
    });
}

/**
 *
 * @param {string} id of product being retrieved
 * @returns promise that data will be returned
 */
async function getProduct(id) {
  return axios
    .get(`/api/product/${id}`)
    .then((res) => {
      //parse json string into javascript object.
      const ingredients = JSON.parse(res.data.status.nutritional_data);
      //object to hold all product info
      //ingredient info will be pushed onto arraying using map function

      const productInfo = {
        dsld_id: res.data.status.dsld_id,
        name: res.data.status.product_name,
        upc: res.data.status.upc,
        purchaseRef: res.data.status.purchase_ref,
        numOfIngredients: Object.keys(ingredients).length,
        numOfOtherIngredients: res.data.status.other_ingredients
          ? res.data.status.other_ingredients.length
          : 0,
        servingSize: res.data.status.serving_size,
        servingType: res.data.status.serving_type,
        message: res.data.status.product_message,
        ingredientNames: [],
        ingredientUnits: [],
        ingredientQuantities: [],
        otherIngredientNames: res.data.status.other_ingredients
          ? res.data.status.other_ingredients
          : [],
        imageLink: res.data.status.image_link,
      };

      //turns object holding ingredient info, into an array that can use array.prototype.map function
      //element is the ingredient name, and it contains two nested variables that hold the amount and respective unit
      Object.keys(ingredients).map((element) => {
        productInfo["ingredientNames"].push(element);
        productInfo["ingredientQuantities"].push(
          ingredients[element]["amount"]
        );
        productInfo["ingredientUnits"].push(ingredients[element]["unit"]);
      });

      return productInfo;
    })
    .catch((err) => {
      console.log("Error searching for product");
    });
}

function Searching({ type, name, parentCallback }) {
  //state equivalent functionality for javascript functions rather than javascript classes
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");

  //calls asynch
  const displayProducts = () => {
    getProducts(search, type).then((data) => setProducts(data));
  };

  const typing = (text) => {
    setSearch(text);
  };

  const textFieldLabel = (type) => {
    if (type == "Product") {
      return "Input partial or full product name";
    }
    return "Input partial or full UPC code";
  };

  /**
   * Calls the Callback function that returns product object to parent component
   */
  const handleProductClick = (product) => {
    parentCallback(product);
  };

  /**
   * creates empty product to send back to parent component
   */
  const handleExitClick = () => {
    const product = {
      dsld_id: "",
      name: "",
      upc: "",
      purchaseRef: "",
      numOfIngredients: "",
      servingSize: "",
      servingType: "",
      message: "",
      ingredientNames: [],
      ingredientUnits: [],
      ingredientQuantities: [],
      imageLink: "",
    };

    parentCallback(product);
  };

  return (
    <div class={styles.backdrop}>
      <div class={styles.popup}>
        <div class={styles.textFieldAndButton}>
          <div class={styles.input}>
            <input
              onChange={(e) => typing(e.target.value)}
              label={textFieldLabel(name)}
              width={1}
              fullWidth={true}
            />
          </div>
          <div class={styles.button}>
            <Button variant="contained" onClick={() => displayProducts()}>
              Search for {name}
            </Button>
          </div>
        </div>
        {!!products
          ? products.map((product) => {
              return (
                //getProduct() retrieves the product based on its id number, formats it into useful product object, and returns it
                //handleProductClick() sends the product back to the parent component
                <div className={styles.productParent}>
                  <div
                    class={styles.productResult}
                    onClick={() =>
                      getProduct(product.dsld_id).then((data) =>
                        handleProductClick(data)
                      )
                    }
                  >
                    {`${product.product_name} - ` +
                      (product.upc ? `${product.upc}` : `No UPC`) +
                      (product.product_message
                        ? product.product_message.substr(0, 8) === "DISABLED"
                          ? ` - Disabled`
                          : ` - Updated`
                        : ``)}
                  </div>
                  <div
                    className={styles.productImage}
                    onClick={() => {
                      product.image_link
                        ? window.open(product.image_link)
                        : window.open(
                            "https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/" +
                              product.dsld_id +
                              ".pdf"
                          );
                    }}
                  >
                    View label
                  </div>
                </div>
              );
            })
          : null}
        <div class={styles.cancel}>
          <Button
            margin="50%"
            variant="contained"
            color="inherit"
            size="small"
            onClick={() => handleExitClick()}
          >
            Exit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Searching;
