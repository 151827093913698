import React from 'react';
import styles from './Help.module.css';
import { IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
//npm install @mui/material @emotion/react @emotion/styled
import { Box, Button, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert'
//npm install @aws-sdk/client-sns
import { SNSClient, PublishCommand } from '@aws-sdk/client-sns';
//npm install --save react-s3
import S3FileUpload from 'react-s3'
import axios from 'axios';

class Help extends React.Component {
	constructor(props) {
    	super(props);

    	this.state = {
			data: {
				name: {
					title: 'Name',
					value: null,
					type: 'string'
				},
				email: {
					title: 'Email Address',
					value: null,
					type: 'string',
				},
				category: {
					title: 'Category',
					value: null,
					options: ['Question', 'Problem', 'Report incorrect information', 'Recommend new product', 'Other'],
				},
				comment: {
					title: 'Question / Comment',
					value: null,
					type: 'string',
				}
			},
			imageUrl: null,
			submissionSuccessOpen: false,
			requireFieldsOpen: false,
			fileUploadErrorOpen: false,
			fileSelected: '', //Will either be blank or "[] Image(s) Added"
			logout: false
		};

  	}

	keypress(event) {
		if(event.keyCode === 13) {
			this.submit()
		}
	}

	componentDidMount() {
		let temp = this.state.data
		let val
		axios.get(`/api/user/${this.props.username}`).then(res => {
			const resData = res.data.status
			val = resData['email']
			temp['email'].value = val
			this.setState({ data: temp })
		})
	}

	//Updates the fields when user types information into the form
	update(field, e, set) {
		let temp = this.state.data
	  	let val = e
		temp[field].value = val
		this.setState({ data: temp })
	}

	//When user clicks submit button
	submit() {
		const name = this.state.data.name.value
		const email = this.state.data.email.value
		const category = this.state.data.category.value
		const comment = this.state.data.comment.value

		//Display message if required fields aren't filled in
		if (name === null || email === null || category === null || comment === null) {
			this.setState({ requireFieldsOpen: true })
		}
		//Submit form if required fields are filled in
		else {

			// //Configure SNS message
			const emailBody = `Name: ${name}  Email: ${email}  Category: ${category}  Comment: ${comment}\n`;
			// Image Location: ${imageLocation}\n`

			//Upload image (if applicable)
			// let e = document.getElementById("file")
			// this.upload(e)

			// let imageLocation = '\n'
			// if (e.files.length == 0) {
			// 	imageLocation = 'N/A'
			// }
			// else if (e.files.length == 1) {
			// 	imageLocation = `#1: https://nutradataimagebucket.s3.amazonaws.com/${this.props.username}/${e.files[0].name}\n`
			// }
			// else {
			// 	for (var i = 0; i < e.files.length; i++) {
			// 		imageLocation = imageLocation + `#${i + 1}: https://nutradataimagebucket.s3.amazonaws.com/${this.props.username}/${e.files[i].name}\n`
			// 	}
			// }

			// call to api to send sns email with the email body as param
			axios.get(`/api/user/${this.props.username}/help/${emailBody}`)
			.then((res) => {
				this.setState({ submissionSuccessOpen: true })
			})
			this.setState({ submissionSuccessOpen: true })

			//need to clear the form to show that the 
		}
	}

	//Upload image (if applicable)
	upload(e) {
		//Check if file is selected
		if (e.files.length == 0) {
			return
		}

		//Only problem with this is that you can't change the key
		//So if there's white space, the link in the SNS email will have to be copied
		//and pasted manually since it will cut off after the first white space
		const config = {
			bucketName: 'nutradataimagebucket',
			region: 'us-east-1',
			accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
			dirName: this.props.username
		}

		for (var i = 0; i < e.files.length; i++) {
			S3FileUpload.uploadFile(e.files[i], config)
			.then((data) => {
				return
			})
			.catch((err) => {
				this.setState({ fileUploadErrorOpen: true })
			})
		}

	}

	//Update file name on screen so user can see file was selected
	updateFileSelected() {
		let e = document.getElementById("file")
		if (e == null || e.value.length == 0) {
			this.setState({ fileSelected: '' })
			return
		}
		else {
			let numFiles = e.files.length
			let message
			if (numFiles == 1) {
				message = '1 Image Added'
			}
			else {
				message = numFiles + ' Images Added'
			}
			this.setState({ fileSelected: message })
		}
	}

	//Remove an image from the form
	removeImage() {
		document.getElementById("file").value = ''
		this.updateFileSelected()
	}

	render() {
		const data = this.state.data
		const selected = this.state.fileSelected

		const handleErrorClose = () => {
			this.setState({ requireFieldsOpen: false })
			return
		}

		const handleSuccessClose = () => {
			this.setState({ submissionSuccessOpen: false })
			return
		}

		const handleFileErrorClose = () => {
			this.setState({ fileUploadErrorOpen: false })
			return
		}

		const Alert = React.forwardRef(function Alert(props, ref) {
			return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
		});

		return(
			<div class={styles.help}>
				<div class={styles.card}>
					<div class={styles.header}>
						{`Reach Out with Questions or Concerns\n Provide Details Below`}
					</div>
					{Object.keys(data).map((field) => {
						return(
              				<div class={styles.input}>
                				<InputLabel>
									* {data[field].title}
								</InputLabel>
                				{data[field].options ? 
                  					<Select
                    					value={data[field].value}
                   						defaultValue={data[field].value}
                    					onChange={(e) => this.update(field, e.target.value, true)}
                    					width={1}
                    					fullWidth={true}
                  					>
                  						{data[field].options.map((option,index) => {
                    						return <MenuItem value={option}>{option}</MenuItem>
                  						})}                 
                  					</Select> : 
                  					<TextField
                    					value={data[field].value}
                    					onChange={(e) => this.update(field, e.target.value, true)}
                   						width={1}
                    					fullWidth={true}
                    					type={data[field].type}
										multiline
                  					/>
                				}
              				</div>
						)
					})}
					<div class={styles.pictureUpload}>
						Upload Image:
						<Box component="span" sx={{ p: 2, border: 0 }}>
							<Button 
								size="small"
								variant="contained"
								component="label"
								style={{
									backgroundColor: "#dedddc",
									color: "black",
									padding: "5px"
								}}>
									Select Image
									<input multiple hidden id="file" type="file" accept="image/*" onChange={() => this.updateFileSelected() }/>
							</Button>
    					</Box>
						<div class={styles.imageSelected}>
							<Box component="span" sx={{ p: 0.5, border: 0 }}>
								{ selected }
    						</Box>
							<Box component="span" sx={{ p: 1.5, border: 0 }}>
								<span hidden={selected == '' ? true : false}>
									<IconButton aria-label="delete" onClick={ () => this.removeImage() }>
										<DeleteIcon>
										</DeleteIcon>
									</IconButton>
								</span>
							</Box>
						</div>
              		</div>
					<div class={styles.submit} onClick={() => this.submit()}>
						Submit Form
					</div>
					<div>
						<Snackbar
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							style={{ height: "60%" }}
							open={this.state.requireFieldsOpen}
							onClose={handleErrorClose}>
							<Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
								Fields marked with * are required
  							</Alert>
						</Snackbar>
						<Snackbar
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							style={{ height: "60%" }}
							open={this.state.submissionSuccessOpen}
							onClose={handleSuccessClose}>
							<Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
								Submission Successful!
  							</Alert>
						</Snackbar>
						<Snackbar
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							style={{ height: "60%" }}
							open={this.state.fileUploadErrorOpen}
							onClose={handleFileErrorClose}>
							<Alert onClose={handleFileErrorClose} severity="error" sx={{ width: '100%' }}>
								Error uploading file
  							</Alert>
						</Snackbar>
					</div>
				</div>
			</div>
		)
	}
}

export default Help;