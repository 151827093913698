import React from "react";
import Landing from "./Components/Landing/Landing";
import Destination from "./Components/Landing/Destination";
import Home from "./Components/Home/Home";
import Google from "./Components/Home/Google";
import { Route } from "react-router-dom";
import styles from "./App.module.css";
import ResetPassword from "./Components/Landing/ResetPassword/ResetPassword";
import ResetPasswordForm from "./Components/Landing/ResetPassword/ResetPasswordForm";
import Disclaimer from "./Components/Landing/Disclaimers/Disclaimer";
import Privacy from "./Components/Landing/PrivacyPolicy/Privacy";
import Disclaim from "./Components/Landing/Disclaim/Disclaim";
import MissionStatement from "./Components/Landing/Mission Statement/MissionStatement";
import Vision from "./Components/Landing/Vision/Vision";
import Contact from "./Components/Landing/Contact Us/Contact";
import Tutorial from "./Components/Landing/Tutorial/Tutorial";
import ReactGA from "react-ga";
import Verify from "./Components/Home/VerifyEmail";

const TRACKING_ID = "UA-247878969-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className={styles.app}>
      <Route path={"/"} exact component={Destination} />
      <Route path={"/landing"} exact component={Landing} />
      <Route path={"/home"} exact component={Home} />
      <Route path={"/login/reset"} exact component={ResetPassword} />
      <Route path={"/login/resetform"} exact component={ResetPasswordForm} />
      <Route path={"/disclaimer"} exact component={Disclaimer} />
      <Route path={"/privacy"} exact component={Privacy} />
      <Route path={"/disclaim"} exact component={Disclaim} />
      <Route path={"/missionstatement"} exact component={MissionStatement} />
      <Route path={"/vision"} exact component={Vision} />
      <Route path={"/contact"} exact component={Contact} />
      <Route path={"/tutorial"} exact component={Tutorial} />
      <Route path={"/verify"} exact component={Verify} />
    </div>
  );
}

export default App;
//   <Route path={'/disclaimer'} exact component={Disclaimer}/>
