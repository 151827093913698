import React from 'react';
import styles from './DisableProduct.module.css';
import axios from 'axios';
import { Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/**
 * This component is responsible for manually disabling products from the database.
 * This component is found on the Admin tab on NutraData.org
 */
class DisableProduct extends React.Component {

    /**
     * The constructor sets the initial values of the state component, which will be used to store most of the data we are using. 
     * When retrieving state variables, we are using this.state.variable_name
     * When Saving data, we are using setState({ variable_name: variable_value })
     */
    constructor(props) {
        super(props)

        this.state = {
            product_id: null,
            message: '',
            error: '',
            success: false,
            search_complete: false,
            collapsed: true,
        };
    }

    /**
         * Callback function for telling Admin.js to search for a product by upc
         */
    handleUPCClick = (e) => {
        this.setState({ search_complete: false })
        const upcDisable = {
            searching: true,
            disableProduct: true,
            updateProduct: false,
            type: 'upc',
            name: 'UPC',
        }
        this.props.parentCallback(upcDisable)
    }

    /**
     * Callback function for telling Admin.js to search for a product by name
     */
    handleNameClick = (e) => {
        this.setState({ search_complete: false })
        const nameDisable = {
            searching: true,
            disableProduct: true,
            updateProduct: false,
            type: 'name',
            name: 'Product',
        }
        this.props.parentCallback(nameDisable)
    }

    /**
     * 
     */
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.product !== this.props.product) {
            if (this.props.product !== null) {

                this.setState({
                    product_id: this.props.product.dsld_id,
                    success: false,
                    search_complete: true,
                })

                if (this.props.product.message !== null) {
                    const temp = this.props.product.message
                    temp.substring(0, 1) === 'D' ? this.setState({ message: temp.substr(10) }) : this.setState({ message: '' })
                }
                else {
                    this.setState({ message: '' })
                }
            }
        }
    }

    //After implementation, I realized this tree could be avoided by using await / async
    disable(product_id, disable_message) {

        //create body that will go into first two database calls
        //These first two calls delete all events associated with a product and 
        //all instances of that product from user inventories that have it
        const body = {
            dsld_id: product_id
        }

        let notify_users = axios.post(`/api/user/disable_notification/${product_id}`).then(res => {
            console.log("Notified users that products are disabled")
        }).catch(err => {
            console.log(err.response.statusText)
        })

        let event_deletion = axios.delete(`/api/event/all_events/${product_id}`, body).then(res => {
            if (res.status === 200) {

                let inventory_deletion = axios.delete(`/api/user/inventory/${product_id}/deleteAll`, body).then(res => {
                    if (res.status === 200) {

                        //set body to generic or custom disable message, using ternary operator
                        let body = { message: null }
                        disable_message ? body = { message: "DISABLED: " + disable_message } : body = { message: "DISABLED: This product was disabled." }

                        let disabled_product = axios.post(`/api/product/disable/${product_id}`, body).then(res => {//update product in NutraData database, sending body containing product information
                            if (res.status === 200) {

                                this.setState({ success: 'Product successfully disabled.' })
                                return true
                            }
                        }).catch(err => {
                            console.log(err.response.statusText)
                        })
                    }

                }).catch(err => {
                    console.log(err.response.statusText)
                })
            }

        }).catch(err => {
            console(err.response.statusText)
        })

    }

    render() {

        const product = this.props.product
        const message = this.state.message
        const err = this.state.error
        const search_complete = this.state.search_complete
        const collapsed = this.state.collapsed

        return (
            <div class={styles.disable}>
                <div class={styles.card}>

                    <div class={styles.header}>
                        <div class={styles.titleAndCollapse}>
                            <div class={styles.title}>
                                {`Disable A Product`}
                            </div>
                            <div class={styles.collapse} onClick={() => this.setState({ collapsed: !collapsed })}>
                                {this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                            </div>
                        </div>
                    </div>
                    {!collapsed?<div> 
                    <div class={styles.buttonDiv}>
                        <Button
                            variant="contained"
                            fullWidth={true}
                            onClick={this.handleNameClick}
                        >
                            Search for Product By Name
                        </Button>
                    </div>
                    <div class={styles.buttonDiv}>
                        <Button
                            variant="contained"
                            fullWidth={true}
                            onClick={this.handleUPCClick}
                        >
                            Search for Product By UPC
                        </Button>
                    </div>
                    {(search_complete && product !== null) ?
                        <div class={styles.body}>
                            <hr />
                            <div class={styles.topParent}>
                                <div class={styles.description}>
                                    This feature disables products by removing them from all user inventories, and ensuring no users can add them. Enter a message on why this product is being disabled (Optional).
                                </div>
                                <div class={styles.exit}>
                                    <Button variant="contained" color="inherit" size="medium" onClick={() => this.setState({ search_complete: false })}>
                                        Exit
                                    </Button>
                                </div>
                            </div>
                            <TextField
                                id="outlined-multiline-static"
                                fullWidth={true}
                                multiline
                                rows={3}
                                value={message}
                                onChange={(e) => this.setState({ message: e.target.value })}
                            />
                            <div class={styles.note}>
                                Note: This message can be viewed by searching for the product here, or in the Update a Product feature. To re-enable any product, update any of its fields using the Update a Product feature.
                            </div>
                            {(product !== null) ? <div class={styles.productInfo}>
                                Product to be disabled: {product.name} {product.upc !== null ? " - " + product.upc : null}
                            </div> : null}
                            <div class={styles.submit} onClick={() => this.disable(product.dsld_id, message)}>
                                Disable Product
                            </div>
                            <div class={err ? styles.error : styles.success}>
                                {err ? err : this.state.success}
                            </div>
                        </div>
                        : null}
                        </div> : null}
                </div>
            </div>
        )


    }

}

export default DisableProduct