import { FETCH_STATUS, LOGIN } from '../actions/types';

const initialState = {
	login: false
}

export default function(state = initialState,  action) {
	switch(action.type) {
		case FETCH_STATUS:
		console.log('test')
			return {
				...state,
				login: action.payload
			}
		default:
			return state;
	}
}