import React from 'react';
import styles from './Visualize.module.css';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, ComposedChart, ReferenceLine, RadialBar, RadialBarChart, PolarAngleAxis, FunnelChart, Funnel, Label, LabelList
} from 'recharts';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
/* Converts old units to new units */
const convert=(numToConvert, nutrient)=>{
  let temp = 0
  if (nutrient.startsWith("Vitamin B12")) {
    console.log(numToConvert)
    temp = numToConvert * 0.4167
  }
  else if (nutrient.startsWith("Niacin")) {
    temp = numToConvert / 60.0
  }
  else if (nutrient.startsWith("Folate")) {
    temp = numToConvert * 2
  }
  
  else {
    if (numToConvert != null) {
      return numToConvert.toFixed(0)
    }
  }
  return (parseFloat('' + temp).toFixed(0))
}
const mg = [
  {
    name: 'Vitamin A', uv: 4000, pv: 2400,
  },
  {
    name: 'Vitamin B', uv: 3000, pv: 1398,
  },
  {
    name: 'Vitamin C', uv: 2000,
  },
  {
    name: 'Vitamin D', uv: 2780, pv: 3908,
  },
  {
    name: 'Vitamin E', uv: 1890, pv: 4800,
  },
  {
    name: 'Vitamin F', uv: 2390, pv: 3800,
  },
  {
    name: 'Vitamin G', uv: 3490, pv: 4300,
  },
  {
    name: 'Vitamin H', pv: 2300,
  },
];

const data01 = [
  {
    "name": "Group A",
    "value": 400
  },
  {
    "name": "Group B",
    "value": 300
  },
  {
    "name": "Group C",
    "value": 300
  },
  {
    "name": "Group D",
    "value": 200
  },
  {
    "name": "Group E",
    "value": 278
  },
  {
    "name": "Group F",
    "value": 189
  }
];
const data02 = [
  {
    "name": "Group A",
    "value": 2400
  },
  {
    "name": "Group B",
    "value": 4567
  },
  {
    "name": "Group C",
    "value": 1398
  },
  {
  	"name": "Group D",
    "value": 9800
  },
  {
    "name": "Group E",
    "value": 3908
  },
  {
    "name": "Group F",
    "value": 4800
  }
];

const pie = [data01, data02]
const pl = pie.length

const percentages = mg.map((nutrient, index) => {
	var temp = Object.assign({}, nutrient);
	const keys = Object.keys(nutrient)
	const values = Object.values(nutrient)
	values.map((a, i) => {
		//use suggestions
		if(i !== 0) {
			const val = Math.round((a /(Math.random()*5000.0)))
			temp[keys[i]] = val
		}
	})
	return temp
})
const CustomLabel = props => {/*
  console.log(props);
   return (
     <g>
       <text x={props.viewBox.x} y={props.viewBox.y} fill="black" dy={20} dx={0}>
         100% RDA
       </text>
       <text x={props.viewBox.x} y={props.viewBox.y} fill="black" dy={525} dx={0}>
         100% RDA
       </text>
       <text x={props.viewBox.x} y={props.viewBox.y} fill="black" dy={1050} dx={0}>
         100% RDA
       </text>
     </g>
   );
  */};
const options = ['Bar Graph', 'Nutrient Ring']
const data = [mg, percentages]



class Visualize extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	selected: 0,
      events: null,
      aggregate: null,
      rdaAggregate: null
    };
  }

  getHistory() {
    axios.get(`/api/user/${this.props.username}/history`).then(res => { 
      if(res.status === 200) {
        this.setState({ events: res.data.status.events })
        this.setState({ aggregate: res.data.status.aggregated })
        this.setState({ rdaAggregate: res.data.status.rdaAggregated })
      }
    }).catch(err => {
      console.log('No events loaded')
    })
  }

  componentDidMount() {
    this.getHistory()
  }

	render() {

    const agg = this.state.aggregate
    const rdaAgg = this.state.rdaAggregate
    const all = this.state.events?.map(item => item.product_name)
    const bar = !!rdaAgg ? Object.keys(rdaAgg)?.map(item => {
      return ({
          name: item,
          Percentage: convert((parseFloat(rdaAgg[item].similar) / rdaAgg[item].rda) * 100,item)
      })
    }) : null


		const select = this.state.selected
		const barHeight = `${70*data[select].length}px`
    const getColor = (value) => {
      let gradient;

      if (value < 25){
        return "#ff0000";
      } else if (value < 75) {
        return "#ffa500";
      } else {
        return "#2ecc71";
      }
    };

    let visualization = null;

    if (window.innerWidth >= 768) {
      visualization = (
        <div class={styles.barwidget}>
          <div 
          class={styles.bar} 
          style={{ height: barHeight }}
          >
            <ResponsiveContainer width="100%" height="200%" >
              <ComposedChart
                data={bar}
                layout="vertical"
                margin={{ right: 100 }}>
                <CartesianGrid strokeDasharray="3 3" fill="white"/>
                <XAxis dataKey="Percentage" type="number" domain={[0,100]} allowDataOverflow={true}>
                </XAxis>
                <YAxis dataKey="name" type="category" width={200} />
                <Tooltip />
                <Bar dataKey="Percentage" type="number" stackId="a" maxBarSize={100}>
                {
                  !!bar ? bar.map((entry, index) => (
                    <Cell key={index} fill={getColor(entry.Percentage)}/>
                  )) : null
                }
                {
                    !!bar ? bar.map((entry, index) => (
                      <LabelList 
                        dataKey="Percentage" 
                        type="number" 
                        position="right" 
                        content={(props) => (
                          <text x={props.x + props.width / 0.99998} y={props.y + props.height / 1.6} fill={getColor(props.value)}>
                            {props.value}%
                          </text>
                        )}
                      />
                    )) : null
                  }
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else {
      visualization = (
        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', height: '100vh'}}>
          {!!bar ? bar.map((entry, index) => (
            <div class={styles.widget}>
              <div 
              key={index}
              class={styles.radialbar} 
              style={{ height: barHeight }}
              >
                <ResponsiveContainer width="200%" height="300%" >
                      <RadialBarChart 
                        width={730} 
                        height={250} 
                        innerRadius="20%" 
                        outerRadius="95%"
                        barSize={15}
                        data={[entry]} 
                        startAngle={90} 
                        endAngle={-270}
                      >
                      <RadialBar minAngle={15} background clockWise={true} isAnimationActive dataKey="Percentage" type="number" fill={getColor(entry.Percentage)} cornerRadius={15}>
                        <LabelList dataKey="Percentage" type="number" position="center" fill={getColor(entry.Percentage)}/>
                      </RadialBar>
                      <PolarAngleAxis type="number" domain={[0, 100]} allowDataOverflow={true} angleAxisId={0} tick={false} />
                      <Legend iconSize={0} iconType='circle' width={500} height={0} align="right" verticalAlign='middle' wrapperStyle={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '150px',
                            marginRight: '193px',
                        }}/>
                      </RadialBarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )) : null}
        </div>
      );
    }

		return(
      <div> 
        <div class={styles.visualize}>
          <div class={styles.username}>
            Percentage of Recommended Daily Allowance for <em>{this.props.username}</em>
          </div>
          {visualization}
        </div>
        <div class={styles.note}>*NOTE: Graph displays whether 100% of a vitamin/supplament's RDA has been met.</div>
      </div>
			
		)
	}
}

export default Visualize;