import React from "react";
import styles from "./Add.module.css";
import Image from "./Types/Image";
import UPC from "./Types/UPC";
import Search from "./Types/Search";
import imageimg from "../../../../Assets/Images/image.png";
import upcimg from "../../../../Assets/Images/upcUpdated.jpg";
import searchimg from "../../../../Assets/Images/search.jpeg";
import Button from "@material-ui/core/Button";

class Add extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
    };
  }

  /**
   *
   * @param {String} type type of add form being shown. Once display is not set to false, it will trigger form to show up
   */
  openType(type) {
    this.setState({ display: type });
  }

  keypress(e) {
    if (e.keyCode === 27) this.setState({ display: false });
  }

  componentDidMount() {
    document.addEventListener("keydown", (e) => this.keypress(e), false);
  }

  /**
   * @param e exits form once the user clicks exit
   */
  exit() {
    this.setState({ display: false });
  }

  render() {
    const addTypes = {
      "Scan Barcode": {
        img: imageimg,
        component: <Image username={this.props.username} />,
      },
      "Search UPC Code": {
        img: upcimg,
        component: <UPC username={this.props.username} />,
      },
      "Search Product Name": {
        img: searchimg,
        component: <Search username={this.props.username} />,
      },
    };

    return (
      <div class={styles.add}>
        <div class={styles.types}>
          {Object.keys(addTypes).map((type, index) => {
            return (
              <div
                class={styles.method}
                key={type}
                onClick={() => this.openType(type)}
                style={{ backgroundImage: `url(${addTypes[type].img})` }}
              >
                <div class={styles.typeText}>{type}</div>
                <div class={styles.methodBackground}></div>
              </div>
            );
          })}
        </div>
        {this.state.display ? (
          <div class={styles.backdrop}>
            <div class={styles.popup}>
              {addTypes[this.state.display].component}
              <div class={styles.cancel}>
                <Button
                  margin="50%"
                  variant="contained"
                  color="inherit"
                  size="small"
                >
                  {/* Select Item Name from list to add Supplement */}
                </Button>
                <Button
                  margin="50%"
                  variant="contained"
                  color="inherit"
                  size="small"
                >
                  {/* please view label to ensure proper supplement is being added */}
                </Button>
                {/* <Button
                  margin="50%"
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.exit();
                  }}
                >
                  Exit
                </Button> */}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Add;
