import React from 'react';
import axios from 'axios';
import styles from './Shop.module.css'

class Shop extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	recommendations: null
    };
  }

  getRecommendations() {
  	axios.get(`/api/user/${this.props.username}/recommendations`).then(res => { 
		  if(res.status === 200) {
		  	this.setState({ recommendations: res.data.status })
		  }
		}).catch(err => {
	  	console.log('No recomendations loaded')
		})
  }

  componentDidMount() {
    this.getRecommendations()
  }

	render() {
		const rec = this.state.recommendations
		return(
			<div class={styles.shop}>
				<div class={styles.recommendations}>
					Recommendations
				</div>
				<div class={styles.title}>
					Minerals
				</div>
				{
					rec?.minerals.map(({ name, amount, unit}) => {
						return <div>{`${name} - ${amount+unit}`}</div>
					})
				}
				<div class={styles.title}>
					Vitamins
				</div>
				{
					rec?.vitamins.map(({ name, amount, unit}) => {
						return <div>{`${name} - ${amount+unit}`}</div>
					})
				}
			</div>
		)
	}
}

export default Shop;
