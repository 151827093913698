import React from "react";
import styles from "./Totals.module.css";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
/* NEED TO RUN "npm install @material-ui/icons" AND "npm install @material-ui/core" AND " npm install @material-ui/lab" TO HAVE THIS COMPILE */
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

class Totals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      event: 0,
      dsld: null,
      error: null,
    };
  }

  rowStyle(ri) {
    if (ri % 2 === 1) return styles.rowWhite;
    else return styles.rowGray;
  }

  /* Go back to main inventory page, reset values, and refresh inventory */
  exit() {
    this.setState({ event: 0 });
    this.setState({ dsld: null });
    this.componentDidMount();
  }

  componentDidMount() {
    axios
      .get(`/api/user/${this.props.username}/inventory`)
      .then((res) => {
        this.setState({ data: res.data.status });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /* Open popup asking if user wants to permanently remove */
  removeChoice() {
    return (
      <Alert className={styles.alert} variant="outlined" severity="warning">
        Permanently remove product from inventory?
        <Box
          className={styles.removeCancel}
          display="flex"
          justifyContent="space-evenly"
        >
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            onClick={() => {
              this.remove();
            }}
          >
            Remove
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            onClick={() => {
              this.exit();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Alert>
    );
  }

  /* Actually remove the product from the user's inventory */
  remove() {
    axios
      .delete(`/api/user/${this.props.username}/${this.state.dsld}`, null)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ error: null });
          this.exit(); //calls exit function which will refresh inventory after
        }
      })
      .catch((err) => {
        this.setState({ error: err.response.data.status });
      });
  }

  render() {
    const data = this.state.data;
    const e = this.state.event;
    const titles = ["Product Name", "UPC", "Date Added to Inventory"];

    return (
      <div class={styles.totals}>
        <div class={styles.username}>
          Inventory of <em>{this.props.username}</em>
        </div>
        <div class={styles.table}>
          <div class={styles.header}>
            {titles.map((cell, ci) => {
              return <div class={styles.headerCell}>{cell}</div>;
            })}
          </div>
          {!!data ? (
            data
              .sort((a, b) => a.product_name.localeCompare(b.product_name))
              .map((product, ri) => {
                return (
                  <div class={this.rowStyle(ri)}>
                    <div class={styles.cell}>{product.product_name}</div>
                    <div class={styles.cell}>{product.upc}</div>
                    <div class={styles.cell}>
                      {new Date(product.created_at)
                        .toString()
                        .split(" ")
                        .slice(0, 4)
                        .join(" ")}
                      <div class={styles.delete}>
                        <IconButton
                          value={product.dsld_id}
                          aria-label="delete"
                          onClick={(e) =>
                            this.setState({
                              dsld: e.currentTarget.value,
                              event: 1,
                            })
                          }
                        >
                          <DeleteIcon></DeleteIcon>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div class={styles.rowWhite}>
              <div class={styles.cell}>No items added to inventory yet</div>
            </div>
          )}
        </div>

        {!!e ? (
          <div class={styles.backdrop}>
            <div class={styles.popup}>{this.removeChoice()}</div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Totals;
