import React from 'react';
import styles from './Types.module.css'
import Searching from './Searching/Searching';

function Search({ username, value}) {
	return (
		<div class={styles.cs}>
			<Searching username={username} type={'name'} name={'Product'}/> 
		</div>
	)
}

export default Search