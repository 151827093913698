import React from 'react';
import TextField from '@mui/material/TextField';
import styles from './UpdateProduct.module.css';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import { Button } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/**
 * This component is responsible for manually updating products that are currently in the database.
 * It asks the user to search for a product by name or upc, then retrieves data for that product. 
 * That retrieved data is put into the input fields and the user can then modify any of the data.
 * This component is found on the Admin tab on NutraData.org
 */

class UpdateProduct extends React.Component {
    /**
     * The constructor sets the initial values of the state component, which will be used to store most of the data we are using. 
     * When retrieving state variables, we are using this.state.variable_name
     * When Saving data, we are using setState({ variable_name: variable_value })
     */
    constructor(props) {
        super(props)

        this.state = {
            product_id: null,
            data: {
                name: {
                    title: '* Product Name',
                    value: null,
                    type: 'text'
                },
                upc: {
                    title: 'UPC Code',
                    value: null,
                    type: 'text'
                },
                purchaseRef: {
                    title: 'Contact Information (Name, Address)',
                    value: null,
                    type: 'text'
                },
                numOfIngredients: {
                    title: '* Number of Ingredients',
                    value: null,
                    type: 'number'
                },
                servingSize: {
                    title: '* Serving Size',
                    value: null,
                    type: 'text'
                },
                servingType: {
                    title: '* Serving Type',
                    value: null,
                    options: ['Capsule', 'Scoop', 'Softgel', 'Tablet', 'Tablespoon', 'Teaspoon']
                },
                numOfOtherIngredients: {
                    title: '* Number of Other Ingredients',
                    value: null,
                    type: 'number'
                },
                imageLink: {
                    title: 'Product Label Image URL (should start with "https:")',
                    value: null,
                    type: 'text'
                }
            },
            ingredientNames: [''],
            ingredientUnits: [''],
            ingredientQuantities: [''],
            otherIngredientNames: [],
            message: '',
            error: '',
            success: false,
            searchComplete: false,
            collapsed: true,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        //Resets success after any state changes that werent a success change
        if (prevState.success !== false && this.state.success === 'Product successfully updated.') {
            this.setState({ success: false })
        }
        //checks if a product was passed into the component
        //if so set corresponding state data to the passed in product info
        if (prevProps.product !== this.props.product) {
            if (this.props.product !== null) {

                console.log(this.props.product)
                //Insert data object from state into temp. Doing this since you can't set values of nested state variables
                var temp = { ...this.state.data }
                //set data from props
                temp.name.value = this.props.product.name
                temp.purchaseRef.value = this.props.product.purchaseRef
                temp.numOfIngredients.value = this.props.product.numOfIngredients
                temp.numOfOtherIngredients.value = this.props.product.numOfOtherIngredients
                temp.servingSize.value = this.props.product.servingSize
                temp.servingType.value = this.props.product.servingType
                temp.imageLink.value = this.props.product.imageLink

                //if upc is null, it wont change the upc showed on screen. 
                //This ensures that if a product without a upc is searched for right after a product with a upc is updated, the upc will disappear on screen 
                this.props.product.upc ? temp.upc.value = this.props.product.upc : temp.upc.value = ''

                //fixes same issue as upc
                let message = ''
                if (this.props.product.message) {
                    message = this.props.product.message.substr(9).trim()
                }

                let otherIngredientNames = []
                if (this.props.product.otherIngredientNames !== null) {
                    otherIngredientNames = this.props.product.otherIngredientNames
                }

                //set all state data from props
                this.setState({
                    product_id: this.props.product.dsld_id,
                    data: temp,
                    message: message,
                    ingredientNames: this.props.product.ingredientNames,
                    ingredientQuantities: this.props.product.ingredientQuantities,
                    ingredientUnits: this.props.product.ingredientUnits,
                    otherIngredientNames: otherIngredientNames,
                    searchComplete: true,
                    error: '',
                })
            }
        }
    }

    /**
         * 
         * @param {string} field - supplement variable
         * @param {string} e - value being set to supplement variable
         */
    updateData(field, e) {

        /**
         * "We do not want to mutate the state directly in React applications"
         * We set temp to state data, modify the value for the field given, then set state to temp data
         */
        switch (true) {
            case (field === 'servingSize' && !(/^\.0*[1-9]\d*$|^0(\.0*[1-9]\d*)$|^[1-9]\d*?(\.\d+)?$/.test(e))):
                this.setState({ error: "ERROR: Serving size needs to be a positive, non-zero number." })
                break
            //Return after next one as we do not want user to be able to set value to 0 using the down arrow.
            case (field === 'numOfIngredients' && e === '0'):
                return
            case (field === 'numOfIngredients' && !(/^[1-9]\d*$/.test(e))):
                this.setState({ error: "ERROR: Number of Ingredients needs to be a positive integer." })
                break
            case (field === 'numOfOtherIngredients' && e === '-1'):
                return
            case (field === 'name' && e === ''):
                this.setState({ error: "ERROR: Input product name." })
                break
            default:
                this.setState({ error: '' })
                break
        }

        //sets new values to any variables changed in data object in the state component
        let temp = this.state.data
        let val = e
        /**
         * It is important to save null to upc state variable when there is no value being given
         * This is because the database has a constraint that upc codes must be unique, so 
         * there can't be more than one product with '' as its upc code
         * However, upc codes don't have to be present, so we can use null
        */
        if (field === 'upc' && val === '') {
            temp[field].value = null
        }
        else {
            temp[field].value = val
        }
        this.setState({ data: temp })

        //adds or deletes fields to the ingredient arrays' based on the number of ingredients inputted.
        //only changes size when a valid number is inputted by the user
        if (field === 'numOfIngredients' && /^[1-9]\d*$/.test(val)) {

            let ingredientNamesTemp = this.state.ingredientNames
            let ingredientUnitsTemp = this.state.ingredientUnits
            let ingredientQuantitiesTemp = this.state.ingredientQuantities

            while (ingredientNamesTemp.length < val) {
                ingredientNamesTemp.push('')
                ingredientUnitsTemp.push('')
                ingredientQuantitiesTemp.push('')
            }

            while (ingredientNamesTemp.length > val) {
                ingredientNamesTemp.pop('')
                ingredientUnitsTemp.pop('')
                ingredientQuantitiesTemp.pop('')
            }

            this.setState({ ingredientNames: ingredientNamesTemp, ingredientUnits: ingredientUnitsTemp, ingredientQuantities: ingredientQuantitiesTemp })

        }

        if (field === 'numOfOtherIngredients' && /(0)|(^[1-9]\d*$)/.test(val)) {
            let otherIngredientNamesTemp = this.state.otherIngredientNames

            while (otherIngredientNamesTemp.length < val) {
                otherIngredientNamesTemp.push('')
            }

            while (otherIngredientNamesTemp.length > val) {
                otherIngredientNamesTemp.pop('')
            }

            this.setState({ otherIngredientNames: otherIngredientNamesTemp })
        }
    }

    /**
    * 
    * @param {String} arrayType : type of ingredient array being changed
    * @param {int} index : index in ingredient array being changed
    * @param {String} e : new value to be inserted into ingredient array
    */
    updateIngredients(arrayType, index, e) {

        let ingredientArray
        let val = e

        switch (arrayType) {
            case "Names":
                ingredientArray = this.state.ingredientNames
                ingredientArray[index] = val
                this.setState({ ingredientNames: ingredientArray })
                break;
            case "Quantities":
                ingredientArray = this.state.ingredientQuantities
                ingredientArray[index] = val
                this.setState({ ingredientQuantities: ingredientArray })
                break;
            case "Units":
                ingredientArray = this.state.ingredientUnits
                ingredientArray[index] = val
                this.setState({ ingredientUnits: ingredientArray })
                break;
        }

    }

    updateProduct() {

        //makes sure all fields are filled before submitting, and resets when successful
        switch (true) {
            case (this.state.ingredientNames.includes('')
                || this.state.ingredientQuantities.includes('')
                || this.state.ingredientUnits.includes('')
                || this.state.otherIngredientNames.includes('')
                || this.state.data.name.value === ''
                || this.state.data.numOfIngredients.value === ''
                || this.state.data.servingType.value === ''
                || this.state.data.servingSize.value === ''):
                this.setState({ error: 'ERROR: Please input information for all fields with a *.' })
                return true
            case (this.state.error !== ''
                && this.state.error !== 'ERROR: Please input information for all fields with a *.'):
                return true
            default:
                this.setState({ error: '' })
                break;
        }

        // (1)
        let nutritional_data = '{'//resets nutritional data variable
        let nutrition_count = this.state.data.numOfIngredients.value//stores count of ingredients in product

        for (let i = 0; i < nutrition_count; i++) {//reformats ingredient data to string object to be put inside NutraData database
            nutritional_data += '"' + this.state.ingredientNames[i] + '":{"amount":"' + this.state.ingredientQuantities[i] + '","unit":"' + this.state.ingredientUnits[i] + '"}'
            //Adds comma after every ingredient but last
            if (i < nutrition_count - 1 && nutrition_count > 1) {
                nutritional_data += ","
            }
        }
        nutritional_data += '}'

        //set generic update message, if no custom
        let message = "UPDATED: This message was updated."
        if (this.state.message !== '') {
            message = "UPDATED: " + this.state.message
        }

        //set upc to null, unless there is inputted upc code
        //due to unique upc constraint, upc code must be null in the database if there is none inputted
        let upc = null
        if (this.state.data.upc.value !== '') {
            upc = this.state.data.upc.value
        }

        let otherIngredients = null
        if (this.state.otherIngredientNames.length !== 0) {
            otherIngredients = 'ARRAY' + JSON.stringify(this.state.otherIngredientNames).replaceAll("'", "''").replaceAll('["', "['").replaceAll('"]', "']").replaceAll('","', "','")
        }

        // (2) NOTE: Body objects that are used as parameters for this api call must have these variables.
        //  The product controller file located in NUTRADATA/app/server/controllers uses them when calling the accessor methods.
        //  Data flow is as follows insertProduct.js -> server/routes/product_routes.js -> server/controllers/product_controller.js -> server/db/accessors/product_accessor.js -> PostgreSQL database
        const body = {
            dsld_id: this.state.product_id,
            product_name: this.state.data.name.value,
            upc: upc,
            purchase_ref: this.state.data.purchaseRef.value,
            nutritional_data: nutritional_data,
            serving_size: this.state.data.servingSize.value,
            serving_type: this.state.data.servingType.value,
            message: message,
            other_ingredients: otherIngredients,
            image_link: this.state.data.imageLink.value === ''? null: this.state.data.imageLink.value,
        }
        if (this.state.message === '') {
            body.message = "UPDATED: This product was updated."
        }

        // (3)
        return axios.post(`/api/product/update/${body.dsld_id}`, body).then(res => {//update product in NutraData database, sending body containing product information
            if (res.status === 200) {
                this.setState({ success: 'Product successfully updated.' })
                return true
            }
        }).catch(err => {
            return err.response.statusText
        })
    }

    /**
     * 
     * @param {*} data : type of input field being rendered (for special case input fields (EX: number input fields need arrows))
     * @param {String} field : name of input field being rendered
     * @returns desired input field
     * 
     * This will return desired input fields based on function parameters
     */
    renderSwitch(data, field) {

        switch (true) {

            case field === "purchaseRef":
                return <TextField
                    value={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    width={1}
                    fullWidth={true}
                    type={data[field].type}
                    multiline
                    variant="standard"
                />

            case field === "servingSize":
                return <TextField
                    value={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    width={1}
                    fullWidth={true}
                    variant="standard"
                />

            case field === "servingType":
                return <TextField
                    value={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    width={1}
                    fullWidth={true}
                    variant="standard"
                />
            case field === "numOfIngredients":
                return <div>
                    <TextField
                        value={data[field].value}
                        onChange={(e) => this.updateData(field, e.target.value)}
                        width={1}
                        fullWidth={true}
                        type={data[field].type}
                        variant="standard"
                    />

                    {
                        //Number of Ingredient Input fields vary based on the user designated ingredient count
                        //For code readability, this part of the component is put in its own method
                        this.renderIngredientInputs()
                    }

                </div>
            case field === "numOfOtherIngredients":
                return <div>
                    <TextField
                        value={data[field].value}
                        onChange={(e) => this.updateData(field, e.target.value)}
                        width={1}
                        fullWidth={true}
                        type={data[field].type}
                        variant="standard"
                    />

                    {
                        this.renderOtherIngredientInputs()
                    }

                </div>
            default:
                return <TextField
                    value={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    fullWidth={true}
                    type={data[field].type}
                    variant="standard"
                />
        }
    }

    /**
     * maps the other ingredient names array
     * @returns : div containing all other ingredient input fields
     */
    renderOtherIngredientInputs() {

        let otherIngredientNamesTemp = this.state.otherIngredientNames
        let value = ''

        return <div class={styles.ingredientContainer}>
            <div class={styles.otherNameContainer}>
                <br />
                {
                    Object.keys(otherIngredientNamesTemp).map((name, index) => {
                        return <div>
                            <br />
                            <InputLabel>* Other Ingredient {index + 1}</InputLabel>
                            <TextField
                                fullWidth={true}
                                value={otherIngredientNamesTemp[index]}
                                variant="standard"
                                onChange={(e) => {

                                    value = e.target.value
                                    otherIngredientNamesTemp[index] = value
                                    this.setState({ otherIngredientNames: otherIngredientNamesTemp })

                                }}
                            />
                        </div>
                    })
                }
            </div>
            <br />
        </div>
    }

    /**
     * Sequentially maps each ingredient array 
     * @returns : div containing all ingredient input fields
     */
    renderIngredientInputs() {

        let ingredientNamesTemp = this.state.ingredientNames
        let ingredientUnitsTemp = this.state.ingredientUnits
        let ingredientQuantitiesTemp = this.state.ingredientQuantities

        return <div class={styles.ingredientContainer}>

            <div class={styles.nameContainer}>
                <br />
                {
                    Object.keys(ingredientNamesTemp).map((name, index) => {
                        return <div class={styles.ingredientName}>
                            <InputLabel>* Ingredient {index + 1}</InputLabel>
                            <TextField
                                label={"Name"}
                                fullWidth={true}
                                value={ingredientNamesTemp[index]}
                                onChange={(e) => this.updateIngredients("Names", index, e.target.value)}
                                variant="standard"
                            />
                        </div>

                    })
                }
            </div>
            <div class={styles.quantityContainer}>
                <br />
                {
                    Object.keys(ingredientQuantitiesTemp).map((quantity, index) => {
                        return <div class={styles.ingredientQuantity}>
                            <TextField
                                label={"Quantity"}
                                value={ingredientQuantitiesTemp[index]}
                                onChange={(e) => this.updateIngredients("Quantities", index, e.target.value)}
                                variant="standard"
                            />
                        </div>
                    })
                }
            </div>
            <div class={styles.unitContainer}>
                <br />
                {
                    Object.keys(ingredientUnitsTemp).map((unit, index) => {
                        return <div class={styles.ingredientUnit}>
                            <TextField
                                onChange={(e) => this.updateIngredients("Units", index, e.target.value)}
                                value={ingredientUnitsTemp[index]}
                                label={"Unit"}
                                fullWidth={true}
                                variant="standard"
                            />
                        </div>

                    })
                }
            </div>
            <br />
        </div>
    }

    /**
     * Callback function for telling Admin.js to searching for a product by upc
     */
    handleUPCClick = (e) => {
        this.setState({ searchComplete: false })
        const upcUpdate = {
            searching: true,
            disableProduct: false,
            updateProduct: true,
            type: 'upc',
            name: 'UPC',
        }
        this.props.parentCallback(upcUpdate)
    }

    /**
     * Callback function for telling Admin.js to searching for a product by name
     */
    handleNameClick = (e) => {
        this.setState({ searchComplete: false })
        const nameUpdate = {
            searching: true,
            disableProduct: false,
            updateProduct: true,
            type: 'name',
            name: 'Product',
        }
        this.props.parentCallback(nameUpdate)
    }

    render() {

        const data = this.state.data
        const message = this.state.message
        const err = this.state.error
        const collapsed = this.state.collapsed

        return (
            <div class={styles.update}>
                <div class={styles.card}>

                    <div class={styles.header}>
                        <div class={styles.titleAndCollapse}>
                            <div class={styles.title}>
                                {`Update A Product`}
                            </div>
                            <div class={styles.collapse} onClick={() => this.setState({ collapsed: !collapsed })}>
                                {this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                            </div>
                        </div>
                    </div>
                    {!collapsed?<div> 
                        <div class={styles.buttonDiv}>
                        <Button
                            variant="contained"
                            fullWidth={true}
                            onClick={this.handleNameClick}
                        >
                            Search for Product By Name
                        </Button>
                    </div>
                    <div class={styles.buttonDiv}>
                        <Button
                            variant="contained"
                            fullWidth={true}
                            onClick={this.handleUPCClick}
                        >
                            Search for Product By UPC
                        </Button>
                    </div>
                    {(this.state.searchComplete && this.props.product !== null) ?
                        <div>
                            <div class={styles.line}><hr /></div>
                            <div class={styles.topParent}>
                                <div class={styles.asteriskMessage}>
                                    {'* indicates a required field'}
                                </div>
                                <div class={styles.exit}>
                                    <Button variant="contained" color="inherit" size="medium" onClick={() => this.setState({ searchComplete: false })}>
                                        Exit
                                    </Button>
                                </div>
                            </div>
                            {Object.keys(data).map((field, i) => {
                                return (
                                    <div class={styles.input}>
                                        <InputLabel>{data[field].title}</InputLabel>
                                        {
                                            this.renderSwitch(data, field)
                                        }
                                    </div>
                                )
                            })}
                            <div class={styles.input}>
                                <InputLabel>Enter a message on why this product is being updated (optional).</InputLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    margin="dense"
                                    value={message}
                                    fullWidth={true}
                                    multiline
                                    rows={3}
                                    onChange={(e) => this.setState({ message: e.target.value })}
                                />
                                <div class={styles.note}>
                                    Note: Editing any disabled products message will re-enable it, and allow users to use that product again.
                                </div>
                            </div>
                            <div class={styles.submit} onClick={() => this.updateProduct()}>
                                Update Product
                            </div>
                            <div class={err ? styles.error : styles.success}>
                                {err ? err : this.state.success}
                            </div>
                        </div> : null}
                </div>: null}
                </div>
            </div>
        )


    }


}

export default UpdateProduct