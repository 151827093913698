import { useState } from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import React from 'react';
import styles from './Vision.module.css';


import axios from 'axios';
const Vision = () => {
    return (
        <div class={styles.card}> 
        <div class={styles.vision}> 
        <div class={styles.header}>
        {`Our Vision \n`}
		</div>
        <br/>
        <div class={styles.text}>
  NutraData is currently a supplement tracking application that allows users to easily monitor the supplements they are taking. NutraData was founded in response to the problem that supplements tracking is challenging and often unreliable; our app solves this issue by providing a <b>modern</b>,<b> reliable</b>, and inviting platform for users to track their supplement consumption, including comparisons of ingredient dosages to RDA amounts, and possible cross-element reactions. 
</div>
<br />
<br />
<div class={styles.text}>

<button class = {styles.button}
          onClick={ () => {window.history.back()}  }>
          Back to home page
        </button>
        </div>
        </div>
        </div>


    );

}



export default Vision;