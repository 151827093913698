import React, { useState, useEffect } from "react";
import styles from './BetaTesters.module.css'

class BetaTesters extends React.Component {
    render () {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>Feedback Form</h1>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSek9_lqDZQh6LFcGA-pUtxtz7xsBmw__LmVJTLmLo5n13rFEQ/viewform?embedded=true" 
                width="640" 
                height="1954" 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0">
                    Loading…
                </iframe>
            </div>
        )
    }
}

export default BetaTesters;