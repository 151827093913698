import React from 'react';
import { TextField } from '@material-ui/core';
import styles from './InsertProduct.module.css';
import { Select } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/**
 * This component is responsible for manually inserting products into the database.
 * It takes user input, validates the information to ensure fields are filled and are the correct variable types,
 * then inserts the product into the database. 
 * This component is found on the Admin tab on NutraData.org
 */
class InsertProduct extends React.Component {
    /**
     * The constructor sets the initial values of the state component, which will be used to store most of the data we are using. 
     * When retrieving state variables, we are using this.state.variable_name
     * When Saving data, we are using setState({ variable_name: variable_value })
     */
    constructor(props) {
        super(props);

        this.state = {
            data: {
                name: {
                    title: '* Product Name',
                    value: '',
                    type: 'text'
                },
                upc: {
                    title: 'UPC Code',
                    value: null,
                    type: 'text'
                },
                purchaseRef: {
                    title: 'Contact Information (Name, Address)',
                    value: 'Contact 1: ',
                    type: 'text'
                },
                numOfIngredients: {
                    title: '* Number of Ingredients',
                    value: 1,
                    type: 'number'
                },
                servingSize: {
                    title: '* Serving Size',
                    value: '1',
                    type: 'text'
                },
                servingType: {
                    title: '* Serving Type',
                    value: '',
                    options: ['Capsule', 'Scoop', 'Softgel', 'Tablet', 'Tablespoon', 'Teaspoon']
                },
                numOfOtherIngredients: {
                    title: '* Number of Other Ingredients',
                    value: 0,
                    type: 'number'
                },
                imageLink: {
                    title: 'Product Label Image URL',
                    value: '',
                    type: 'text'
                }
            },
            ingredientNames: [''],
            ingredientUnits: [''],
            ingredientQuantities: [''],
            otherIngredientNames: [],
            manualProductCount: null,
            error: '',
            success: false,
            collapsed: true,
        };
    }

    /**
     * 
     * @param {string} field - supplement variable
     * @param {string} e - value being set to supplement variable
     */
    updateData(field, e) {

        /**
         * "We do not want to mutate the state directly in React applications"
         * We set temp to state data, modify the value for the field given, then set state to temp data
         */
        switch (true) {
            case (field === 'servingSize' && !(/^\.0*[1-9]\d*$|^0(\.0*[1-9]\d*)$|^[1-9]\d*?(\.\d+)?$/.test(e))):
                this.setState({ error: "ERROR: Serving size needs to be a positive, non-zero number." })
                break
            //Return after next one as we do not want user to be able to set value to 0 using the down arrow.
            case (field === 'numOfIngredients' && e === '0'):
                return
            case (field === 'numOfIngredients' && !(/^[1-9]\d*$/.test(e))):
                this.setState({ error: "ERROR: Number of Ingredients needs to be a positive integer." })
                break
            case (field === 'numOfOtherIngredients' && e === '-1'):
                return
            case (field === 'name' && e === ''):
                this.setState({ error: "ERROR: Input product name." })
                break
            default:
                this.setState({ error: '' })
                break
        }

        //sets new values to any variables changed in data object in the state component
        let temp = this.state.data
        let val = e

        if (field === 'upc') {
            val ? temp.upc.value = val : temp.upc.value = null
        }
        else {
            temp[field].value = val
        }

        this.setState({ data: temp })

        //adds or deletes fields to the ingredient arrays' based on the number of ingredients inputted.
        //only changes size when a valid number is inputted by the user
        if (field === 'numOfIngredients' && /^[1-9]\d*$/.test(val)) {

            let ingredientNamesTemp = this.state.ingredientNames
            let ingredientUnitsTemp = this.state.ingredientUnits
            let ingredientQuantitiesTemp = this.state.ingredientQuantities

            while (ingredientNamesTemp.length < val) {
                ingredientNamesTemp.push('')
                ingredientUnitsTemp.push('')
                ingredientQuantitiesTemp.push('')
            }

            while (ingredientNamesTemp.length > val) {
                ingredientNamesTemp.pop('')
                ingredientUnitsTemp.pop('')
                ingredientQuantitiesTemp.pop('')
            }

            this.setState({ ingredientNames: ingredientNamesTemp, ingredientUnits: ingredientUnitsTemp, ingredientQuantities: ingredientQuantitiesTemp })
        }

        if (field === 'numOfOtherIngredients' && /(0)|(^[1-9]\d*$)/.test(val)) {
            let otherIngredientNamesTemp = this.state.otherIngredientNames

            while (otherIngredientNamesTemp.length < val) {
                otherIngredientNamesTemp.push('')
            }

            while (otherIngredientNamesTemp.length > val) {
                otherIngredientNamesTemp.pop('')
            }

            this.setState({ otherIngredientNames: otherIngredientNamesTemp })
        }

    }

    /**
     * 
     * @param {String} arrayType : type of ingredient array being changed
     * @param {int} index : index in ingredient array being changed
     * @param {String} e : new value to be inserted into ingredient array
     */
    updateIngredients(arrayType, index, e) {

        let ingredientArray
        let val = e

        switch (arrayType) {
            case "Names":
                ingredientArray = this.state.ingredientNames
                ingredientArray[index] = val
                this.setState({ ingredientNames: ingredientArray })
                break;
            case "Quantities":
                ingredientArray = this.state.ingredientQuantities
                ingredientArray[index] = val
                this.setState({ ingredientQuantities: ingredientArray })
                break;
            case "Units":
                ingredientArray = this.state.ingredientUnits
                ingredientArray[index] = val
                this.setState({ ingredientUnits: ingredientArray })
                break;
        }

    }

    /**
     * This api call retrieves the current count of manually inserted products in the database, then sets the state variable that stores it.
     * When the state is set, the componentDidUpdate(prevProps, prevState) function will be called, and will trigger this.submit()
     */
    setManualCount() {

        //Makes sure all ingredient quantities are positive, nonzero integers
        for (let index = 0; index < this.state.data.numOfIngredients.value; index++) {
            const element = this.state.ingredientQuantities[index]
            if (!(/^[1-9]*\.?\d*$/.test(element))) {
                this.setState({ error: 'ERROR: Ingredient quantities must be positive, nonzero integers.' })
                return true
            }
        }
        if (this.state.error === 'ERROR: Ingredient quantities must be positive, nonzero integers.') {
            this.setState({ error: '' }, () => this.setManualCount())
        }

        //makes sure all fields are filled before submitting, and resets when successful
        switch (true) {
            case (this.state.ingredientNames.includes('')
                || this.state.ingredientQuantities.includes('')
                || this.state.ingredientUnits.includes('')
                || this.state.otherIngredientNames.includes('')
                || this.state.data.name.value === ''
                || this.state.data.numOfIngredients.value === ''
                || this.state.data.servingType.value === ''
                || this.state.data.servingSize.value === ''):
                this.setState({ error: 'ERROR: Please input information for all fields with a *.' })
                return true
            case (this.state.error !== ''
                && this.state.error !== 'ERROR: Please input information for all fields with a *.'):
                return true
            default:
                this.setState({ error: '' })
                break;
        }

        let count = null;
        axios.get(`/api/product/manual/count`).then(res => {
            count = res.data.status[0].count
            if (res.status === 200) {
                //setState() is not synchronous, but it always triggers a re render after it sets the state variable.
                //We use the second argument to setState, as it is called right after manualProductCount is updated
                this.setState({ manualProductCount: count }, () => this.submit())
                return true
            }
        }).catch(err => {
            console.log(err)
        })

    }

    /**
     * 
     * @returns true when the product successfully is entered into the database
     * 
     * 1. Creates the unique product id by retrieving the newly changed manual product count, and adds it to "M" to signify it is a manually inserted product
     * 2. Formats the ingredient information into a string that can be parsed into variables when the user adds products to their inventory/supplement list
     * 3. Formats other ingredient info
     * 4. Combines all inputted data into the object body, that will be sent to backend to be used to insert the product the database
     * 5. Makes api call with body object created in step 3 as one of its parameters.
     * 
     */
    submit() {

        // (1) NOTE: this manual product count state variable is set in setManualCount(), which calls submit after manualProductCount changes
        const dsld_id = "M" + this.state.manualProductCount

        // (2)
        let nutritional_data = '{'//resets nutritional data variable
        let nutrition_count = this.state.data.numOfIngredients.value//stores count of ingredients in product

        for (let i = 0; i < nutrition_count; i++) {//reformats ingredient data to string object to be put inside NutraData database
            nutritional_data += '"' + this.state.ingredientNames[i] + '":{"amount":"' + this.state.ingredientQuantities[i] + '","unit":"' + this.state.ingredientUnits[i] + '"}'
            //Adds comma after every ingredient but last
            if (i < nutrition_count - 1 && nutrition_count > 1) {
                nutritional_data += ","
            }
        }
        nutritional_data += '}'

        // (3)
        let otherIngredients = null
        if (this.state.otherIngredientNames.length !== 0) {
            otherIngredients = 'ARRAY' + JSON.stringify(this.state.otherIngredientNames).replaceAll("'", "''").replaceAll('["', "['").replaceAll('"]', "']").replaceAll('","', "','")
        }

        // (4) NOTE: Body objects that are used as parameters for this api call must have these variables.
        //  The product controller file located in NUTRADATA/app/server/controllers uses them when calling the accessor methods.
        //  Data flow is as follows insertProduct.js -> server/routes/product_routes.js -> server/controllers/product_controller.js -> server/db/accessors/product_accessor.js -> PostgreSQL database
        const body = {
            dsld_id: dsld_id,
            product_name: this.state.data.name.value,
            upc: this.state.data.upc.value,
            purchase_ref: this.state.data.purchaseRef.value,
            nutritional_data: nutritional_data,
            serving_size: this.state.data.servingSize.value,
            serving_type: this.state.data.servingType.value,
            other_ingredients: otherIngredients,
            image_link: this.state.data.imageLink.value,
        }

        // (5)
        return this.insert(body)
    }

    insert(body) {
        axios.post(`/api/product/manual_insert/${body.dsld_id}`, body).then(res => {//insert product into NutraData database, sending body containing product information
            if (res.status === 200) {
                this.setState({ success: 'Product successfully inserted.' })
                return true
            }
        }).catch((error) => {
            if (error.response) {
                console.log(error.response.data)
                if (error.response.data.status === 'duplicate key value violates unique constraint "product_pkey"') {
                    //we are recursively adding '+' to the dsld_id and calling the function until the product is inserted
                    body.dsld_id = body.dsld_id + '+'
                    const retry = this.insert(body)
                }
                //for more info on error
                //console.log(error.response.status)
                //console.log(error.response.headers)
            }
            else if (error.request) {
                console.log(error.request)
            }
            else {
                console.log('Error', error.message)
            }
        })
    }

    //Called after every rerender
    componentDidUpdate(prevProps, prevState) {
        if (prevState.otherIngredientNames !== this.state.otherIngredientNames) {
            console.log(this.state.otherIngredientNames)
        }
        if (prevState !== this.state) {
            console.log(this.state.data)
        }
        //Resets success after any state changes that werent a success change
        if (prevState.success !== false && this.state.success === 'Product successfully inserted.') {
            this.setState({ success: false })
        }
    }

    /**
     * 
     * @param {*} data : type of input field being rendered (for special case input fields (EX: number input fields need arrows))
     * @param {String} field : name of input field being rendered
     * @returns desired input field
     * 
     * This will return desired input fields based on function parameters
     */
    renderSwitch(data, field) {

        switch (true) {

            case field === "purchaseRef":
                return <TextField
                    value={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    width={1}
                    fullWidth={true}
                    type={data[field].type}
                    multiline
                />

            case field === "servingSize":
                return <TextField
                    value={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    width={1}
                    fullWidth={true}
                />

            case field === "servingType":
                return <Select
                    value={data[field].value}
                    defaultValue={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    width={1}
                    fullWidth={true}
                >
                    {data[field].options.map((option) => {
                        return <MenuItem value={option}>{option}</MenuItem>
                    })}
                </Select>

            case field === "numOfIngredients":
                return <div>
                    <TextField
                        value={data[field].value}
                        onChange={(e) => this.updateData(field, e.target.value)}
                        width={1}
                        fullWidth={true}
                        type={data[field].type}

                    />

                    {
                        //Number of Ingredient Input fields vary based on the user designated ingredient count
                        //For code readability, this part of the component is put in its own method
                        this.renderIngredientInputs()
                    }

                </div>
            case field === "numOfOtherIngredients":
                return <div>
                    <TextField
                        value={data[field].value}
                        onChange={(e) => this.updateData(field, e.target.value)}
                        width={1}
                        fullWidth={true}
                        type={data[field].type}

                    />

                    {
                        this.renderOtherIngredientInputs()
                    }

                </div>
            default:
                return <TextField
                    value={data[field].value}
                    onChange={(e) => this.updateData(field, e.target.value)}
                    fullWidth={true}
                    type={data[field].type}
                />
        }
    }

    /**
     * maps the other ingredient names array
     * @returns : div containing all other ingredient input fields
     */
    renderOtherIngredientInputs() {

        let otherIngredientNamesTemp = this.state.otherIngredientNames
        let value = ''
        console.log(otherIngredientNamesTemp)

        return <div class={styles.ingredientContainer}>
            <div class={styles.otherNameContainer}>
                <br />
                {
                    Object.keys(otherIngredientNamesTemp).map((name, index) => {
                        return <div>
                            <br />
                            <InputLabel>* Other Ingredient {index + 1}</InputLabel>
                            <TextField
                                fullWidth={true}
                                onChange={(e) => {

                                    value = e.target.value
                                    otherIngredientNamesTemp[index] = value
                                    this.setState({ otherIngredientNames: otherIngredientNamesTemp })

                                }}
                            />
                        </div>
                    })
                }
            </div>
            <br />
        </div>
    }

    /**
     * Sequentially maps each ingredient array 
     * @returns : div containing all ingredient input fields
     */
    renderIngredientInputs() {

        let ingredientUnitTypes = ['mg', 'mcg', 'IU', 'RAE', 'DFE', 'NE']
        let ingredientNamesTemp = this.state.ingredientNames
        let ingredientUnitsTemp = this.state.ingredientUnits
        let ingredientQuantitiesTemp = this.state.ingredientQuantities

        return <div class={styles.ingredientContainer}>

            <div class={styles.nameContainer}>
                <br />
                {
                    Object.keys(ingredientNamesTemp).map((name, index) => {
                        return <div class={styles.ingredientName}>
                            <InputLabel>* Ingredient {index + 1}</InputLabel>
                            <TextField
                                label={"Name"}
                                fullWidth={true}
                                onChange={(e) => this.updateIngredients("Names", index, e.target.value)}
                            />
                        </div>

                    })
                }
            </div>
            <div class={styles.quantityContainer}>
                <br />
                {
                    Object.keys(ingredientQuantitiesTemp).map((quantity, index) => {
                        return <div class={styles.ingredientQuantity}>
                            <TextField
                                label={"Quantity"}
                                onChange={(e) => this.updateIngredients("Quantities", index, e.target.value)}
                            />
                        </div>
                    })
                }
            </div>
            <div class={styles.unitContainer}>
                <br />
                {
                    Object.keys(ingredientUnitsTemp).map((unit, index) => {
                        return <div class={styles.ingredientUnit}>
                            <TextField
                                select
                                onChange={(e) => this.updateIngredients("Units", index, e.target.value)}
                                label={"Unit"}
                                fullWidth={true}
                            >
                                {ingredientUnitTypes.map((option) => {
                                    return <MenuItem value={option}>{option}</MenuItem>
                                })}
                            </TextField>
                        </div>

                    })
                }
            </div>
            <br />
        </div>
    }

    render() {

        const data = this.state.data
        const err = this.state.error
        const collapsed = this.state.collapsed

        return (
            <div class={styles.insert}>
                <div class={styles.card}>

                    <div class={styles.header}>
                        <div class={styles.titleAndCollapse}>
                            <div class={styles.title}>
                                {`Manually Insert A Product`}
                            </div>
                            <div class={styles.collapse} onClick={() => this.setState({ collapsed: !collapsed })}>
                                {this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                            </div>
                        </div>
                    </div>
                    
                    {!collapsed?<div> 
                    <div class={styles.asteriskMessage}>
                        {'* Required Fields'}
                    </div>
                    {Object.keys(data).map((field, i) => {
                        return (
                            <div class={styles.input}>
                                <InputLabel>{data[field].title}</InputLabel>
                                {
                                    this.renderSwitch(data, field)
                                }
                            </div>
                        )
                    })}
                    <div class={styles.submit} onClick={() => this.setManualCount()}>
                        Submit
                    </div>
                    <div class={err ? styles.error : styles.success}>
                        {err ? err : this.state.success}
                    </div>
                    </div> : null}
                </div>
            </div>
        )
    }
}

export default InsertProduct;



    // /**
    //  * 
    //  * @param {*} event - for recognizing click
    //  */
    // keypress(event) {
    //     if (event.keyCode === 13) {
    //         this.submit()
    //     }
    // }

    // /**
    //  * This function is always called after component is rendered.
    //  * It creates a "listener" that handles a click of a button
    //  */
    // componentDidMount() {
    //     document.addEventListener("keydown", (e) => this.keypress(e), false);
    // }

