import { useState } from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import React from 'react';
import styles from './contact.module.css';

import axios from 'axios';
const Contact = () => {
    return (
        <div class={styles.card}> 
        <div class={styles.contact}> 
        <div class={styles.header}>
        {`Contact us here: \n`}
		</div>
        <br/>
        <div class={styles.text}> 
        Email the team at: nutradatateam@gmail.com
        </div>
        <br/>
        <div class={styles.text}> 
        <button
          onClick={ () => {window.history.back()}  }>
          Back to home page
        </button>

        </div>
    
        </div>
        </div>


    );

}

export default Contact;