import React, { useState } from "react";
import styles from "./List.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

class FullOtherIngredients extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
	  Generates the css styling for a supplement information row, based on the row index (ri)
	 * @param {number} ri : row number
	 * @returns style for a row
	 */
  rowStyle(ri) {
    if (ri % 2 === 1) {
      return styles.otherRowWhite;
    } else {
      return styles.otherRowGray;
    }
  }

  render() {
    const otherIngredients = this.props.otherIngredients;

    return (
      <div style={{ maxWidth: "100%", overflowX: "auto" }}>
        <table
          border="1"
          style={{
            borderCollapse: "collapse",
            width: "100%",
            fontSize: "10px",
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "4px" }}>Ingredient</th>
              <th style={{ padding: "4px" }}>Items Containing Ingredient</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(otherIngredients).map((ingredient, ri) => (
              <tr key={ri}>
                <td style={{ padding: "4px" }}>{ingredient}</td>
                <td style={{ padding: "4px" }}>
                  {Object.keys(otherIngredients[ingredient]).map(
                    (index, ci) => (
                      <div key={ci}>{otherIngredients[ingredient][index]}</div>
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class FullNutrients extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Generates the css styling for a supplement information row, based on the row index (ri)
   * @param {number} ri : row number
   * @returns style for a row
   */
  rowStyle(ri) {
    if (ri % 2 === 1) {
      return styles.rowWhite;
    } else {
      return styles.rowGray;
    }
  }

  render() {
    const agg = this.props.agg;
    return (
      <div class={styles.nutrientTable}>
        {/* Header information for nutrient table */}
        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table
            border="1"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              fontSize: "10px",
            }}
          >
            <thead>
              <tr>
                <th style={{ padding: "4px" }}>Ingredient</th>
                <th style={{ padding: "4px" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(agg).map((item, ri) => (
                <tr key={ri}>
                  <td style={{ padding: "4px" }}>{item}</td>
                  <td style={{ padding: "4px" }}>
                    {Array.isArray(agg[item].amount)
                      ? agg[item].amount.map((element) => <div>{element}</div>)
                      : null}
                    {Array.isArray(agg[item].unit)
                      ? agg[item].unit.map((element) => <div>{element}</div>)
                      : null}
                    {Array.isArray(agg[item].unit) ? (
                      agg[item].unit.length > 1 ? (
                        <div>~ See Above ~</div>
                      ) : null
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class FullStack extends React.Component {
  constructor(props) {
    super(props);
  }
  rowStyle(ri) {
    if (ri % 2 === 1) return styles.rowWhite;
    else return styles.rowGray;
  }

  parseServingType(servingSize, servingType) {
    //Temporary check to avoid errors. Will remove once database has serving info for all products
    if (servingSize == null) {
      return "";
    }
    if (servingSize == 1) {
      return servingType.replace("(s)", "");
    }
    return servingType.replace("(s)", "") + "s";
  }

  /* Removes () from serving type so it's cleaner */
  removeParentheses(servingType) {
    //Temporary check to avoid errors. Will remove once database has serving info for all products
    if (servingType == null) {
      return "";
    }
    return servingType.replace("(s)", "") + "s";
  }

  /* Calculates the number of servings based on stack amounts */
  calculateServings(breakfast, lunch, dinner, servingSize) {
    let numPills = breakfast + lunch + dinner;
    let sizeFloat = parseFloat(servingSize, 10);
    let size = numPills / sizeFloat;
    let str = size.toString();
    if (str.includes(".")) {
      return size.toFixed(2);
    }
    return size;
  }

  /* Adds .0 to stack values that are whole numbers */
  addDecimal(value) {
    let str = value.toString();
    if (str.includes(".")) {
      return value.toFixed(2);
    }
    return value.toFixed(1);
  }

  addDecimal(value) {
    let str = value.toString();
    if (str.includes(".")) {
      return value.toFixed(2);
    }
    return value.toFixed(1);
  }

  render() {
    const events = this.props.events;

    return (
      <div class={styles.productTable}>
        <div class={styles.productHeader}>
          <div class={styles.headerCheckbox}>{"Included"}</div>
          <div class={styles.headerNameCell}>
            <div>{"Product Name"}</div>
            <div class={styles.message}>
              {"An "} <div class={styles.asterisk}>*</div>{" "}
              {
                " indicates there are older versions of the product that NutraData™ does not store."
              }
            </div>
          </div>
          <div class={styles.headerServingSize}>{"Serving Size"}</div>
          <div class={styles.portionOfServingTwoRows}>
            <div class={styles.portionOfServing}>{"Portion of Serving"}</div>
            <div class={styles.headerBreakfastCell}>{"Breakfast"}</div>
            <div class={styles.headerLunchCell}>{"Lunch"}</div>
            <div class={styles.headerDinnerCell}>{"Dinner"}</div>
          </div>
          <div class={styles.headerTotalCell}>{"Total Servings"}</div>
        </div>

        <div class={styles.totals}>
          {!!events ? (
            <div class={styles.totals}>
              {events.map((product, ri) => (
                <div class={this.rowStyle(ri)} key={product.event_id}>
                  <div class={styles.cellName}>
                    <div class={styles.productImage}>
                      {product.product_name}
                    </div>
                    {product.duplicate_upc ? (
                      <div class={styles.asterisk}>*</div>
                    ) : null}
                  </div>
                  <div class={styles.cellServingSize}>
                    {`${product.serving_size} ${this.parseServingType(
                      product.serving_size,
                      product.serving_type
                    )}`}
                  </div>
                  <div class={styles.cell}>
                    <div class={styles.allThreeStacks}>
                      {/* Breakfast */}
                      <div class={styles.stack}>
                        <div class={styles.stackValues}>
                          {this.addDecimal(product.breakfast)}
                        </div>
                      </div>

                      {/* Lunch */}
                      <div class={styles.stack}>
                        <div class={styles.stackValues}>
                          {this.addDecimal(product.lunch)}
                        </div>
                      </div>

                      {/* Dinner */}
                      <div class={styles.stack}>
                        <div class={styles.stackValues}>
                          {this.addDecimal(product.dinner)}
                        </div>
                      </div>

                      <div class={styles.type}>
                        {this.removeParentheses(product.serving_type)}
                      </div>
                    </div>
                  </div>

                  {/* Total */}
                  <div class={styles.cellTotal}>
                    {this.calculateServings(
                      product.breakfast,
                      product.lunch,
                      product.dinner,
                      product.serving_size
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export { FullNutrients, FullOtherIngredients, FullStack };
