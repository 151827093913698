import React, { useState } from "react";
import styles from "./Interactions.module.css";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
/* NEED TO RUN "npm install @material-ui/icons" AND "npm install @material-ui/core" AND " npm install @material-ui/lab" TO HAVE THIS COMPILE */
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

class Interactions extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      data: [],
      supp_results: [],
      // nutritional_data: [],
      cui: 0,
      event: 0,
      refs: {},
      ids: {},
      names: {},
      dsld: null,
      error: null,
      interactions: [],
    };
  }

  rowStyle(ri) {
    if (ri % 2 === 1) return styles.rowWhite;
    else return styles.rowGray;
  }

  /* Go back to main inventory page, reset values, and refresh inventory */
  exit() {
    this.setState({ event: 0 });
    this.setState({ dsld: null });
    this.componentDidMount();
  }

  componentDidMount() {
    axios
      .get(`/api/user/${this.props.username}/inventory`)
      .then((res) => {
        console.log("hello");
        const data = res.data.status;
        this.setState({ data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleSearch = async (searchTerm) => {
    try {
      console.log("Searching for:", searchTerm);
      const response = await axios.get(
        `api/user/supp_search?q=${encodeURIComponent(searchTerm)}`
      );
      console.log(response.data);
      const cui = response.data.results[0].cui;
      const interactionResponse = await axios.get(
        `api/user/interaction/agent?q=${cui}`
      );
      console.log(interactionResponse.data);
      // Set interactions data in the component's state
      this.setState({ interactions: interactionResponse.data.interactions });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  render() {
    const { interactions } = this.state;
    const titles = ["       ", "Interaction Between:", "        "];
    return (
      <div>
        <h1>Search Example</h1>
        <SearchBar onSearch={this.handleSearch} />
        {/* Display interactions if available */}
        {interactions && (
          <div class={styles.table}>
            <div class={styles.header}>
              {titles.map((cell, ci) => {
                return <div class={styles.headerCell}>{cell}</div>;
              })}
            </div>

            {interactions.map((interaction, index) => (
              <div class={this.rowStyle(index)}>
                <div class={styles.cell}></div>
                <div class={styles.cell}>
                  <p>{interaction.slug}</p>
                </div>
                <div class={styles.cell}></div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

function SearchBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle changes in the search input
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle search submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Pass the search term to the parent component
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
      <input
        type="text"
        placeholder="Input Supplement..."
        value={searchTerm}
        onChange={handleInputChange}
        style={{ width: "80%", fontSize: "1.5rem" }}
      />
      <button type="submit">Search</button>
    </form>
  );
}

export default Interactions;
