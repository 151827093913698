import { useState } from "react";
import { Link } from "react-router-dom";
import styles from './ResetPassword.module.css';
import Form from '../../utilities/Forms'
import React from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';

const ResetPassword = () => {

    const [email, setEmail] = useState('');

    const forgotPassword = async () => {

        let body = {
            email: email,
            username: undefined
        }
        
        await axios.post('/api/user/get_username', body).then(response => { //auth route in server line 26
            
            body.username = response.data.status.username

            alert('Reset password link is sent to '+ email);

            axios.post('/auth/reset', body).then(res => { //auth route in server line 26
                console.log(res)
                if(res.status === 200) {
                    this.setState({ error: 'No error' })
                    this.setState({ step: 1 })
                } 
            })
        }).catch((error) => {
            alert('Error no user found for the email ' + email);
        })
        
	} //end forgot password method
	
    return (
        <div className="row g-0 auth-wrapper">
            <div class={styles.ResetPassword}>
                <div class={styles.card}>
                <div class={styles.header}>
						{`Need to reset your password?\nPlease provide details below`}
					</div>
                    <div class={styles.asteriskMessage}>
						{'Please enter your email and then click on Submit to reset your password:'}
					</div>
                    <div className={styles.input}>
                                <div className={styles.input}>
                                    {/* <input type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        placeholder="Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    /> */}
                                    <TextField 
                                        id="email" 
                                        label="Email" 
                                        variant="standard" 
                                        fullWidth
                                        name="email"
                                        type="email" 
                                        value={email}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                
                                <div className={styles.submit} onClick={() => {forgotPassword()}}>
                                    Submit
                                </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
} //end of reset password class

export default ResetPassword;

//<div className={styles.submit2}><Link className="text-link" to="/">Back to Login</Link></div> 