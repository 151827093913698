import axios from "axios"; //used for api routes

const showHelpWidget = (type, search) => {
  let subject = "";
  if (type === "barcode") {
    subject = `Barcode Scan Faliure: ${search}`;
  } else {
    subject = `Search Failure: ${search}`;
  }
  window.FreshworksWidget("open");
  window.FreshworksWidget("prefill", "ticketForm", {
    subject: subject,
    description:
      "Product Name:\nServing Size:\nServings Per Container:\nSupplement Type (Capsules, Tablets, Liquid, Etc.):\nSupplement Manufacturer\n**Please attach an image of the product below**",
    priority: 4,
    type: "Recommend New Product",
  });
};

async function submitSuppRequest(barcode, username) {
  console.log(username);
  console.log(barcode);
  return axios
    .post(`/api/product/insert/request`, {
      username: username,
      upc: barcode,
    })
    .then((res) => {
      console.log(res);
      return res.data.status;
    })
    .catch((err) => {});
}

export { showHelpWidget, submitSuppRequest };
