import { useState } from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import React from 'react';
import styles from './Mission.module.css';
import { Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
const MissionStatement = () => {
 
    return (
        <div class={styles.card}> 
        <div class={styles.mission}> 
        <div class={styles.header}>
        {`Our Mission Statement \n`}
		</div>
        <br/>
       <div class={styles.text}> 
       Our mission is to empower individuals in their pursuit of healthy lifestyles by providing accessible and user-friendly vitamin and nutrient tracking solutions. We believe in making it straightforward for people from all walks of life to monitor their nutritional intake, ultimately contributing to their overall well-being.       </div>
       <br/>
       <div class={styles.text}> 
       <button className={styles.button}
          onClick={ () => {window.history.back()}  }>
          Back to home page
        </button>

        </div>
        </div>
        </div>


    );

}

export default MissionStatement;