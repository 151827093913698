import React from 'react';
import styles from './Account.module.css'
import { TextField, Select } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const fStatusOptions = [null, 'none', 'pregnant1st', 'pregnant2nd_1', 'pregnant2nd_2', 'pregnant3rd', 'lactating1st', 'lactating2nd']

class Account extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	data: {
    		sex: {
          title: 'Sex',
          value: '',
          options: ['N/A', 'MALE', 'FEMALE']
        },
    		birth_date: {
          title: 'Birth Date',
          value: '',
          type: 'date',
        },
    		weight: {
          title: 'Weight (lbs)',
          value: '',
          type: 'number',
        },
    		height: {
          title: 'Height (inches)',
          value: '',
          type: 'number',
        },
    		f_status: {
          title: 'Pregnant or Lactating',
          value: '',
          options: ['N/A', 'Not Pregnant or Lactating', 'Pregnant - 1st Trimester', 'Pregnant - 2nd Trimester (Less than 20 Weeks)', 'Pregnant - 2nd Trimester (More than 20 Weeks)', 'Pregnant - 3rd Trimester', 'Lactating - 0-6 months', 'Lactating - Over 7 months']
        },
        activity: {
          title: 'Activity Level',
          value: '',
          options: ['Sedentary', 'Low Active', 'Active', 'Very Active']
        },
    	},
    	error: '',
    	success: '',
    	logout: false
    };
  }

  update(field, e, set) {
  	let temp = this.state.data
    let val = e
    if(field === 'f_status' && !set) val = temp.f_status.options[fStatusOptions.indexOf(e)]
    if(field === 'birth_date') val = e.split('T')[0]

  	temp[field].value = val
  	this.setState({ data: temp })
  }

  submit() {
    const data = this.state.data
    const sex = data.sex.value
    const fStatus = sex === 'FEMALE' ? fStatusOptions[data.f_status.options.indexOf(data.f_status.value)] : 'none'
    const activity = data.activity.value

    const body = {
      birth_date: data.birth_date.value,
      height: parseInt(data.height.value), 
      weight: parseInt(data.weight.value),
      f_status: fStatus,
      sex: sex,
      activity: activity,
    }
    
  	axios.put(`/api/user/${this.props.username}`, body).then(res => {
      if(res.status === 200) {
        this.setState({ error: '' })
        return this.setState({ success: 'Successfully saved'})
      }
     }).catch(err => {
      console.log(err)
    })  	
  }

  keypress(event) {
  	if(event.keyCode === 13) {
  		this.submit()
  	}
  }

  getUserData() {
    axios.get(`/api/user/${this.props.username}`).then(res => {
      const resData = res.data.status
      
      Object.keys(this.state.data).map(field => this.update(field, resData[field], false))
    }).catch(err => {
      console.log(err)
    })
  }

  componentDidMount() {
    document.addEventListener("keydown", (e)=>this.keypress(e), false);
    this.getUserData()
  }

	render() {
		const data =this.state.data
		const err = this.state.error
		
		return(
			<div class={styles.account}>
				{(window.innerWidth >= 768) && (<div class={styles.card}>
					<div class={styles.header}>
            <Stack direction="row" spacing={1}>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
              <h4>{`${this.props.username}`}</h4>
            </Stack>
					</div>
					{Object.keys(data).map((field, i) => {
						return(
              <div class={styles.input}>
                <InputLabel>{data[field].title}</InputLabel>
                {data[field].options ? 
                  <Select
                    value={data[field].value}
                    defaultValue={data[field].value}
                    onChange={(e) => this.update(field, e.target.value, true)}
                    width={1}
                    fullWidth={true}
                  >
                  {data[field].options.map((option,index) => {
                    return <MenuItem value={option}>{option}</MenuItem>
                  })}                 
                  </Select> : 
                  <TextField
                    value={data[field].value}
                    onChange={(e) => this.update(field, e.target.value, true)}
                    width={1}
                    fullWidth={true}
                    type={data[field].type}
                  />
                }
              </div>
						)
					})}
					<div class={err ? styles.error : styles.success}>
						{err ? err : this.state.success}
					</div>
					<div class={styles.submit} onClick={() => this.submit()}>
						Save Changes
					</div>
				</div>)}

        {(window.innerWidth <= 768) && (<div>
					<div class={styles.header}>
            <Stack direction="row" spacing={1}>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
              <h4>{`${this.props.username}`}</h4>
            </Stack>
					</div>
					{Object.keys(data).map((field, i) => {
						return(
              <div class={styles.input}>
                <InputLabel>{data[field].title}</InputLabel>
                {data[field].options ? 
                  <Select
                    value={data[field].value}
                    defaultValue={data[field].value}
                    onChange={(e) => this.update(field, e.target.value, true)}
                    width={1}
                    fullWidth={true}
                  >
                  {data[field].options.map((option,index) => {
                    return <MenuItem value={option}>{option}</MenuItem>
                  })}                 
                  </Select> : 
                  <TextField
                    value={data[field].value}
                    onChange={(e) => this.update(field, e.target.value, true)}
                    width={1}
                    fullWidth={true}
                    type={data[field].type}
                  />
                }
              </div>
						)
					})}
					<div class={err ? styles.error : styles.success}>
						{err ? err : this.state.success}
					</div>
					<div class={styles.submit} onClick={() => this.submit()}>
						Save Changes
					</div>
				</div>)}
				<div class={styles.message}>
					Recommended Daily Allowances (RDAs) are preset to "adults and children over 4 years old." Update your account information above to personalize the RDA's shown on the <em>Home</em> and <em>Visualize</em> tabs
        </div>

			</div>
		)
	}
}

export default Account;

