import { useState } from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import React from 'react';
import styles from './Disclaim.module.css';
import axios from 'axios';
const Disclaim = () => {
    return (
        <div class = {styles.disclaim}>
        WEBSITE DISCLAIMER<br/>
        <br/>
The information provided by NutraData™ ("we," "us," or "our") on Nutradata.org (the "Site") is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.<br/>

<button
          onClick={ () => {window.history.back()}  }>
          Back to home page
        </button>
    </div>
    );
} //end of reset password class

export default Disclaim;

//<div className={styles.submit2}><Link className="text-link" to="/">Back to Login</Link></div> 