import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

export default function VerifyEmail() {
  const location = useLocation();
  const history = useHistory();
  const [verified, setVerified] = useState(null); // Initialize as null for loading state

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    const code = params.get("code");

    // Ensure email and code are present in URL params before making API calls
    if (email && code) {
      // Check verification status
      axios
        .post("/api/user/get_username", {
          email: email,
        })
        .then((res) => {
          console.log(res.data.status.verified);
          if (res.data.status.verified) {
            setVerified(true); // Already verified
          } else {
            // Redirect for verification if not verified
            const origin =
              process.env.NODE_ENV === "development"
                ? "http://localhost:8080/"
                : "https://nutradata.org/";

            const redirectURI = `${origin}auth/verify?email=${email}&code=${code}`;

            axios.get(redirectURI).catch((err) => {
              console.error(err);
              setVerified(false); // Set to false to indicate unverified in case of error
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setVerified(false); // Set to false if there's an error in checking status
        });
    } else {
      setVerified(false); // Set to false if email or code is missing
    }
  }, [location]);

  if (verified === null) {
    return <p>Loading...</p>; // Render loading state initially
  }

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Email Verification</h1>
      {verified ? (
        <>
          <p>Your email is already verified.</p>

          <p>
            You may now <a href="/">go to the homepage</a>.
          </p>
        </>
      ) : (
        <>
          <p>Thank you for verifying your email!</p>
          <p>
            You may now <a href="/">go to the homepage</a>.
          </p>
        </>
      )}
    </div>
  );
}
