import React, { useState, useEffect } from 'react';
import styles from './Message.module.css';
import axios from 'axios';

export default function Message({ username, messages, parentCallback }) {

    const [disabledProducts, setDisabledProducts] = useState(Array());

    useEffect(() => {
        for (let i = 0; i < messages.length; i++) {
            if (messages[i][0] === 'disable') {
                getProduct(messages[i][1])
            }
        }
    }, [])

    async function getProduct(id) {
        const retreivedProduct = await axios.get(`/api/product/${id}`)
        let product = {
            product_name: retreivedProduct.data.status.product_name,
            product_message: retreivedProduct.data.status.product_message
        }
        setDisabledProducts(disabledProducts => [...disabledProducts, product])
    }

    async function deleteAccountMessage(username) {
        await axios.put(`/api/user/account_message/delete/${username}`)
        parentCallback()
    }

    return (
        <div class={styles.backdrop}>
            <div class={styles.popup}>
                <div class={styles.header}>
                    <h1>Welcome back {username}!</h1>
                </div>
                <div class={styles.body}>
                    <p>We have disabled one or more of the products you have been using:</p>
                    <ul>
                        {!!disabledProducts ? disabledProducts.map((product, i) => { return <div key={i}>{product.product_name} - {product.product_message.substring(10)}</div> }) : null}
                    </ul>
                    <p>Products are disabled for various reasons including product recalls, product fraud, or a new version of the product being introduced to the market. NutraData™ strives to provide the safest, and most up-to-date dietary tracking system for users, so we have removed these products from your inventory and supplement list for you. We do not want our users to consume faulty or outdated products. To see any messages on why a product is disabled, search for the product in the add tab. We hope you understand, and we thank you for using our service.</p>
                    <p>- Noel Novak and the NutraData™ Team</p>

                    </div>
                    <div class={styles.submit} onClick={() => deleteAccountMessage(username)}>
                        Okay
                    </div>
                
            </div>
        </div>
    )

}