import { useState } from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import React from 'react';



import axios from 'axios';
const Tutorial = () => {
    return (
        <div> 
            Please use the user tutorial video below as a reference: 
        <button
          onClick={ () => {window.history.back()}  }>
          Back to home page
        </button>

        </div>
        
    
    );

}



export default Tutorial;