import React from 'react';
import styles from './Types.module.css';
import Searching from './Searching/Searching';

function UPC({ username }) {
	return (
		<div class={styles.cs}>
			<Searching username={username} type={'upc'} name={'UPC'}/> 
		</div>
	)
}

export default UPC