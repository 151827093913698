import React from 'react';
import styles from './Admin.module.css';
import { InputLabel, TextField } from '@material-ui/core';
import axios from 'axios';
import InsertProduct from './InsertProduct/InsertProduct';
import UpdateProduct from './UpdateProduct/UpdateProduct';
import DownloadProduct from './DownloadProduct/DownloadProduct';
import DisableProduct from './DisableProduct/DisableProduct';
import Searching from './Searching/Searching';
import AutoUpdate from './AutoUpdate/AutoUpdate';

class Admin extends React.Component {

    //initializes updateProducts state variable to false.
    //the callback function sent into UpdateProduct can change this variable
    constructor() {
        super()

        this.state = {
            //stores data retrieved from parent callback function sent into the Searching component
            //type and name state the way the user wants to search for the product (by upc or by name)
            //when searching is true, the Searching component is shown
            //since the Searching component is used for both removing and updating products, updateProduct, and disableProduct identify what component the product being searched for is sent to
            searchData: {
                type: '',
                name: '',
                searching: false,
                updateProduct: false,
                disableProduct: false,
            },
            productInfo: {
                dsld_id: '',
                name: '',
                upc: '',
                purchaseRef: '',
                numOfIngredients: '',
                numOfOtherIngredients: '',
                servingSize: '',
                servingType: '',
                message: '',
                ingredientNames: [],
                ingredientUnits: [],
                ingredientQuantities: [],
                otherIngredientNames: [],
                imageLink: '',
            },
            //this is set to true only when a product is clicked on, if a user clicks exit on Searching component, this won't allow the returned product info to be sent into any component
            productRetrieved: false,
        }
    }

    //sent into the update and remove product components to retrieve how to search for a product, and where to send the product once its retrieved
    handleSearchTypeCallback = (childData) => {
        this.setState({ 
            searchData: childData, 
            productRetrieved: false,
        })
    }

    //Sent into the Search component to retrieve product information
    //handles product information after being retrieved.
    //need to
    //1. reset searchData state object
    //2. set product state object
    //3. identify whether or not a product was retrieved, or if user clicked exit in search
    handleSearchInfoCallback = (childData) => {

        const product = childData

        //Resetting the searchData state variable so the search form is removed once a product is selected
        const temp = {
            type: '',
            name: '',
            searching: false,
            updateProduct: this.state.searchData.updateProduct,
            disableProduct: this.state.searchData.disableProduct,
        }
        this.setState({ searchData: temp, productInfo: product })

        //identifies if a product was retrieved
        if(product.dsld_id === ''){
            temp.updateProduct = false
            temp.disableProduct = false
            this.setState({productRetrieved: false})
        }
        else{
            this.setState({productRetrieved: true})
        }

    }

    render() {

        const name = this.state.searchData.name
        const type = this.state.searchData.type
        const product = this.state.productInfo
        
        console.log(this.state.searchData)


        return (
            <div class={styles.admin}>
                <div class={styles.parent}>
                    <div class={styles.child}>
                        <DownloadProduct />
                    </div>
                    <div class={styles.child}>
                        <InsertProduct />
                    </div>
                    <div class={styles.child}>
                        <UpdateProduct 
                            //parent callback for retrieving how to search for a product
                            parentCallback={this.handleSearchTypeCallback} 
                            //If a product is searched and clicked, its data is retrieved and sent into this component
                            product={(this.state.productRetrieved && this.state.searchData.updateProduct)? product: null} 
                        />
                    </div>
                    <div class={styles.child}>
                        <DisableProduct 
                            //parent callback for retrieving how to search for a product
                            parentCallback={this.handleSearchTypeCallback} 
                            //If a product is searched and clicked, its data is retrieved and sent into this component
                            product={(this.state.productRetrieved && this.state.searchData.disableProduct)? product: null} 
                        />
                    </div>
                    <div class={styles.child}>
                        <AutoUpdate />
                    </div>
                </div>
                {this.state.searchData.searching ? <Searching type={type} name={name} parentCallback={this.handleSearchInfoCallback} /> : null}
            </div>

        )
    }
}

export default Admin