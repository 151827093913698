import React from 'react';
import styles from './AutoUpdate.module.css';
import { Input, InputLabel, TextField } from '@material-ui/core';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Table from "./Table";

class AutoUpdate extends React.Component {

    //initializes state variables to ""
    constructor() {
        super()

        // const columns = [
        //         {
        //             Header: "Updates",

        //             columns: [
        //                 {
        //                     Header: "Update ID",
        //                     accessor: "show.id"
        //                 },
        //                 {
        //                     //Status = active, finished, cancelled
        //                     Header: "Status",
        //                     accessor: "show.status"
        //                 },
        //                 {
        //                     Header: "Inserted",
        //                     accessor: "show.inserted"
        //                 },
        //                 {
        //                     Header: "Updated",
        //                     accessor: "show.updated"
        //                 },
        //                 {
        //                     Header: "Latest versions checked",
        //                     accessor: "show.checked"
        //                 },
        //                 {
        //                     Header: "Replaced with latest version",
        //                     accessor: "show.replaced"
        //                 },
        //                 {
        //                     Header: "Date Started",
        //                     accessor: "show.started"
        //                 },
        //                 {
        //                     Header: "Date Ended",
        //                     accessor: "show.ended"
        //                 }
        //             ]
        //         }
        //     ]

        this.state = {
            updateInfo: null,
            collapsed: true,
            error: '',
            success: '',
            updateProduct: true,
            startUpdate: '',
            stopUpdate: '',
        }
    }

    //Called after every rerender
    componentDidUpdate(prevProps, prevState) {
    }

    check_update = async () => {

        try {
            const res = await axios.get(`/api/db_update/active`)
            if (res.data.status.length === 0) {
                alert('There are no current updates in progress.')
            }
            else {
                alert('There is an update in progress.')
            }
        } catch (error) {
            console.log(error)
        }

    }

    stop_update = async () => {

        let active_update
        let unactive_update

        try {
            active_update = await axios.get(`/api/db_update/active`)
            console.log(active_update)
        } catch (error) {
            console.log(error)
        }

        if (active_update.data.status.length === 0) {
            alert('No active updates to stop')
        }
        else {
            alert('Stopping current update.')

            var AWS = require('aws-sdk');

            var myConfig = new AWS.Config({
                accessKeyId: "AKIARXXKPPQ4VUZGEREO",
                secretAccessKey: "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",
            });

            AWS.config.update({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: "AKIARXXKPPQ4VUZGEREO",
                    secretAccessKey: "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",
                }
            });


            var params = {
                InstanceIds: [active_update.data.status[0].instance_id],
                DryRun: true,
            }
            console.log(params)
            var ec2 = new AWS.EC2();
            ec2.terminateInstances(params, function (err, data) {
                if (err && err.code === 'DryRunOperation') {
                    params.DryRun = false;
                    ec2.terminateInstances(params, async function (err, data) {
                        if (err) {
                            console.log("Error", err);
                        } else if (data) {
                            console.log("Success", data);
                            try {

                                let body = {
                                    instance_id: active_update.data.status[0].instance_id
                                }

                                unactive_update = await axios.post(`/api/db_update/unset_active`, body)
                                console.log(unactive_update)


                            } catch (error) {
                                console.log(error)
                            }

                        }
                    });
                } else {
                    console.log("You don't have permission to terminate instances");
                }
            })

            // ec2.stopInstances(params, function (err, data) {
            //     if (err && err.code === 'DryRunOperation') {
            //         params.DryRun = false;
            //         ec2.stopInstances(params, function (err, data) {
            //             if (err) {
            //                 console.log("Error", err);
            //             } else if (data) {
            //                 console.log("Success", data.StoppingInstances);
            //             }
            //         });
            //     } else {
            //         console.log("You don't have permission to stop instances");
            //     }
            // })
        }
    }

    //When user clicks submit button
    start_update = async () => {

        try {
            const res = await axios.get(`/api/db_update/active`)
            if (res.data.status.length !== 0) {
                alert('There is already an update in progress.')
                return
            }
        } catch (error) {
            console.log(error)
        }

        try {
            const res = await axios.post(`/api/db_update/create`)
            if (res.data.status.length === 0) {
                alert('Update failed to start.')
                return
            }
            alert('Update Started.')
            console.log(res)
        } catch (error) {
            console.log(error)
        }

        var AWS = require('aws-sdk');

        var myConfig = new AWS.Config({
            accessKeyId: "AKIARXXKPPQ4VUZGEREO",
            secretAccessKey: "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",
        });

        AWS.config.update({
            region: 'us-east-1',
            credentials: {
                accessKeyId: "AKIARXXKPPQ4VUZGEREO",
                secretAccessKey: "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",
            }
        });

        var params = {
            ImageId: "ami-026b57f3c383c2eec",
            InstanceType: "t2.micro",
            KeyName: "ethansAutoUpdateKey",
            MaxCount: 1,
            MinCount: 1,
            InstanceInitiatedShutdownBehavior: "terminate",
            UserData: "IyEvYmluL2Jhc2gKIyBQcm9ncmFtOgojIEVDMiBpbml0aWFsbHkgaW5zdGFsbCBub2RlLmpzLCBnaXQgZm9yIGRldmVsb3BtZW50IGVudmlyb25tZW50LgojIFlvdSBjYW4gbW9kaWZ5IG5vZGV2IGFuZCBudm12IGZvciBjaGFuZ2luZyBub2RlIGFuZCBudm0gdmVyc2lvbi4KIyBTZXQgcGVybWlzc2lvbiB0byBlYzItdXNlciBpbnN0YWxsIGFib3ZlLgpzaHV0ZG93biAtaCArMzAwMApob21lPS9ob21lL2VjMi11c2VyCm5vZGV2PScxNicKbnZtdj0nMC4zNC4wJwoKc3UgLSBlYzItdXNlciAtYyAiY3VybCBodHRwczovL3Jhdy5naXRodWJ1c2VyY29udGVudC5jb20vY3JlYXRpb25peC9udm0vdiR7bnZtdn0vaW5zdGFsbC5zaCB8IGJhc2giCnN1IC0gZWMyLXVzZXIgLWMgIm52bSBpbnN0YWxsICR7bm9kZXZ9IgpzdSAtIGVjMi11c2VyIC1jICJudm0gdXNlICR7bm9kZXZ9IgoKIyBpbnN0YWxsIGdpdCBhbmQgcmVwbwp5dW0gaW5zdGFsbCBnaXQgLXkKeXVtIGluc3RhbGwgc2NyZWVuCnN1IC0gZWMyLXVzZXIgLWMgImdpdCBjbG9uZSBodHRwczovL2docF9KdDZmbkVwbzY4dE1DUHF5b054T2JCTGR1cEpxbzkycXBtUk1AZ2l0aHViLmNvbS9udXRyYWRhdGEvYXV0b21hdGVkRGF0YWJhc2VVcGRhdGUuZ2l0IgoKIyBydW4gYW5kIGluc3RhbGwgZGVwZW5kZW5jaWVzIGluIHByb2dyYW0sIHRoZW4gdGVybWluYXRlIGVjMiB3aGVuIGZpbmlzaGVkCnN1IC0gZWMyLXVzZXIgLWMgInNjcmVlbiAtZG1TIG15X3NjcmVlbiBiYXNoIC1jICdjZCBhdXRvbWF0ZWREYXRhYmFzZVVwZGF0ZTsgbnBtIGk7IG5vZGUgaW5kZXguanM7IHN1ZG8gc2h1dGRvd24gLWggbm93JyIK",
        };

        var ec2 = new AWS.EC2();
        ec2.runInstances(params, async (err, data) => {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                console.log(data);           // successful response
                const body = {
                    instance_id: data.Instances[0].InstanceId
                }
                console.log(body.instance_id)
                const res = await axios.post(`/api/db_update/instance_id`, body)
            }
            /*
            data = {
            }
            */
        });
    }

    retrieve_update_info = async () => {
        try {
            const res = await axios.get(`/api/db_update/all`)
            if (res.data.status.length === 0) {
                alert('There is no current update information')
            }
            else {
                this.setState({ updateInfo: res.data.status }, () => { console.log(this.state.updateInfo) })
                console.log(res)
            }
        } catch (error) {
            console.log(error)
        }

    }

    /**
    * maps the other ingredient names array
    * @returns : div containing all other ingredient input fields
    */
    renderUpdateInfo() {

        let updateInfo = this.state.updateInfo;
        let value = ''
        console.log(updateInfo)



        return <div>
            <br />
            {
                updateInfo.map((currentValue) => {
                    return <div> 
                        <InputLabel>Update ID: {currentValue.update_id}</InputLabel>
                        <InputLabel>Is the update currently active: {currentValue.active? 'Yes' : 'No'}</InputLabel>
                        <InputLabel>Date and time started: {currentValue.created_at}</InputLabel>
                        <InputLabel>Date and time finished: {currentValue.last_modified}</InputLabel>
                        <InputLabel>Was update cancelled: {currentValue.active && currentValue.entry_dates_verified === null? 'No' : 'Yes'}</InputLabel>
                        {
                        currentValue.entry_dates_verified? 
                        <div>
                        <InputLabel>Products inserted: {currentValue.products_inserted}</InputLabel>
                        <InputLabel>Products updated: {currentValue.products_updated}</InputLabel>
                        <InputLabel>Products that had entry dates verified: {currentValue.entry_dates_verified}</InputLabel>
                        <InputLabel>Products that were replaced with newer versions: {currentValue.products_replaced}</InputLabel>
                        <br /> 
                        </div>: <br />
                        }
                    </div>
                })

                // Object.keys(otherIngredientNamesTemp).map((name, index) => {
                //     return <div>
                //         <br />
                //         <InputLabel>* Other Ingredient {index + 1}</InputLabel>
                //         <TextField
                //             fullWidth={true}
                //             value={otherIngredientNamesTemp[index]}
                //             variant="standard"
                //             onChange={(e) => {

                //                 value = e.target.value
                //                 otherIngredientNamesTemp[index] = value
                //                 this.setState({ otherIngredientNames: otherIngredientNamesTemp })

                //             }}
                //         />
                //     </div>
                // })
            }
        </div>
    }


    render() {

        const err = this.state.error
        const collapsed = this.state.collapsed
        const updateInfo = this.state.updateInfo

        return (
            <div class={styles.autoUpdate}>
                <div class={styles.card}>

                    <div class={styles.header}>
                        <div class={styles.titleAndCollapse}>
                            <div class={styles.title}>
                                {`Perform Full Database Update`}
                            </div>
                            <div class={styles.collapse} onClick={() => this.setState({ collapsed: !collapsed })}>
                                {this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                            </div>
                        </div>
                    </div>

                    {!collapsed ? <div>
                        <div class={styles.body}>
                            <div class={styles.description}>Perform a full database update using the NIH's Dietary Supplement Label Database. This will insert all newly added products, update all non-manually updated products, and ensure the latest version of each product is being stored.</div>
                            <hr />
                            <form>

                                <div class={styles.center}>
                                    Check if there is an update running currently
                                </div>
                                <div class={styles.submit} onClick={() => { this.check_update() }}>
                                    Check for Update
                                </div>

                                <InputLabel>
                                    Enter 'StartUpdate' and click Update Database to start update process:
                                </InputLabel>
                                <TextField
                                    fullWidth margin="dense"
                                    variant="outlined"
                                    onChange={(e) => this.setState({ startUpdate: e.target.value })}
                                />
                                <div class={styles.note}>Note: An update will only initiate if there is not an update currently taking place.</div>
                                <div class={styles.submit} onClick={() => { this.state.startUpdate === 'StartUpdate' ? this.start_update() : alert('StartUpdate needs to be typed into the field.') }}>
                                    Update Database
                                </div>

                                <InputLabel>
                                    Enter 'StopUpdate' and click Cancel Current Update to check if there is an ongoing update process and terminate it:
                                </InputLabel>
                                <TextField
                                    fullWidth margin="dense"
                                    variant="outlined"
                                    onChange={(e) => this.setState({ stopUpdate: e.target.value })}
                                />
                                <div class={styles.submit} onClick={() => { this.state.stopUpdate === 'StopUpdate' ? this.stop_update() : alert('StopUpdate needs to be typed into the field.') }}>
                                    Cancel Current Update
                                </div>
                                <div class={styles.center}>
                                    Retrieve update information from all past updates
                                </div>
                                <div class={styles.submit} onClick={() => { this.retrieve_update_info() }}>
                                    Retrieve Information
                                </div>
                                {this.state.updateInfo ? this.renderUpdateInfo() : null}

                            </form>
                            <div class={err ? styles.error : styles.success}>
                                {err ? err : this.state.success}
                            </div>
                        </div>
                    </div> : null}
                </div >
            </div >
        )
    }

}

export default AutoUpdate