import { useState } from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import React from 'react';
import styles from './ResetPassword.module.css';
import axios from 'axios';
//const bcrypt = require('bcrypt');


const ResetPasswordForm = () => {

    const [passhash, setPassHash] = useState('');
    const [validate, setValidate] = useState({});
    const [email, setEmail] = useState('');


    const validateforgotPassword = () => {
        let isValid = true;

        let validator = Form.validator({
            email: {
                value: email,
                isRequired: true,
                isEmail: true
            }
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors
            })

            isValid = false
        }
        return isValid;
    } //end validate forgot password form


    const forgotPassword = () => {
        //e.preventDefault();
        const validate = validateforgotPassword();
        //const salt = bcrypt.genSaltSync();
        
       //const passhash = bcrypt.hashSync(e.password, salt);
       //const salt = bcrypt.genSaltSync();
       const body = {
           password: passhash,
           email: email
       }

        if (validate) {
            alert('Great, your password is reset! ');
            setValidate({});
			setPassHash('');
        }
        axios.post('/auth/password', body).then(res => { //auth route in server line 26
		    if(res.status === 200) {
                this.setState({ error: 'No error' })
		    	this.setState({ step: 1 })
		    }
		  })
	} //end forgot password method
	

    return (
        <div className="row g-0 auth-wrapper">
        <div class={styles.ResetPassword}>
            <div class={styles.card}>
            <div class={styles.header}>
                    {`Reset Your Password: \nPlease provide details below`}
                </div>
                <div class={styles.asteriskMessage}>
                    {'Please enter your email and new password below and then click on Submit to reset your password: '}
                </div>
                <div className={styles.input}>
                            <div className={styles.input}>
                                    <input type="email"
                                        className={`form-control ${validate.validate && validate.validate.email ? 'is-invalid ' : ''}`}
                                        id="email"
                                        name="email"
                                        value={email}
                                        placeholder= "Enter your email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                    <div className={`invalid-feedback text-start ${(validate.validate && validate.validate.email) ? 'd-block' : 'd-none'}`} >
                                        {(validate.validate && validate.validate.email) ? validate.validate.email[0] : ''}
                                    </div>
                                </div>

                                <div className={styles.input}>
                                    <input type="password"
                                        className={`form-control ${validate.validate && validate.validate.email ? 'is-invalid ' : ''}`}
                                        id="password"
                                        name="password"
                                        value={passhash}
                                        placeholder="Enter new password"
                                        onChange={(e) => setPassHash(e.target.value)}
                                    />
                                </div>
                                <div className={styles.submit} onClick={() => {forgotPassword()}}>
                                    Submit
                                </div>
                </div>
            </div>
        </div>
    </div>
        
        
    );
} //end of reset password class
/*
//original 
<div className="row g-0 auth-wrapper">
                        <div className="auth-form-container text-start">
                        <form className="auth-form" method="POST" onSubmit={forgotPassword} autoComplete={'off'}>
                                <div className="email mb-3">
                                    <input type="email"
                                        className={`form-control ${validate.validate && validate.validate.email ? 'is-invalid ' : ''}`}
                                        id="email"
                                        name="email"
                                        value={email}
                                        placeholder= "Please type in your Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                    <div className={`invalid-feedback text-start ${(validate.validate && validate.validate.email) ? 'd-block' : 'd-none'}`} >
                                        {(validate.validate && validate.validate.email) ? validate.validate.email[0] : ''}
                                    </div>
                                </div>

                                <div className="email mb-3">
                                    <input type="password"
                                        className={`form-control ${validate.validate && validate.validate.email ? 'is-invalid ' : ''}`}
                                        id="password"
                                        name="password"
                                        value={passhash}
                                        placeholder="Please enter new password"
                                        onChange={(e) => setPassHash(e.target.value)}
                                    />
                                </div>
                                
                                <div className="text-center">
                                    <button type="submit">Reset Password</button>
                                </div>
                            </form>

                            <hr />
                            <div className="auth-option text-center pt-2"><Link className="text-link" to="/" >Back to Login</Link></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );*/

export default ResetPasswordForm;