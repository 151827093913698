import React from 'react';
import styles from './DownloadProduct.module.css';
import { InputLabel, TextField } from '@material-ui/core';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

class DownloadProduct extends React.Component {

    //initializes state variables to ""
    constructor() {
        super()

        this.state = {
            data: {
                firstID: "",
                secondID: "",
            },
            collapsed: true,
            error: '',
            success: '',
            updateProduct: true
        }
    }

    //Called after every rerender
    componentDidUpdate(prevProps, prevState) {
    }

    //When user clicks submit button
    submit() {
        //initialize range values that were updated through update(field, e)
        const firstID = this.state.data.firstID
        const secondID = this.state.data.secondID
        if (secondID < firstID) {
            throw ': Not cool'
        }
        //for range firstID -> secondID, insert/update DSLD products into NutraData database
        for (let id = firstID; id <= secondID; id++) {
            insertProduct(id).then(response => {
                
                //if(response === 'Duplicate upc present' ) {
                    if (response.status === 'Duplicate upc present') {

                        const dsld_date = response.dsld_date
                        const upc = (response.upc)
                        getProductUPC(upc).then(response => {

                            const old_id = response.dsld_id
                            getDSLDEntryDate(old_id).then(response => {
                                
                                const nutradata_date = new Date(response)
                                const new_id = id

                                if(dsld_date > nutradata_date) {
                                    replaceProduct(new_id, old_id)
                                }
                                else {
                                    setDuplicateProduct(old_id)
                                }
                                
                            })
                            //validateEntryDate(id)

                        })

                    }
                    //getProductByUpc(id).then(response => {console.log(response)})
                //}
            })
           
        }
        console.log(firstID, secondID)

    }

    //Updates the fields when user types information into the form
    /**
     * 
     * @param field is the field of the state object "data"
     * @param e i
     */
    update(field, value) {

        let temp = this.state.data //initialize data object from state as temp
        temp[field] = value //set specified field
        this.setState({ data: temp })  //set data object of state to temp

    }

    render() {

        const err = this.state.error
        const collapsed = this.state.collapsed

        return (
            <div class={styles.download}>
                <div class={styles.card}>

                    <div class={styles.header}>
                        <div class={styles.titleAndCollapse}>
                            <div class={styles.title}>
                                {`Download Products from the DSLD`}
                            </div>
                            <div class={styles.collapse} onClick={() => this.setState({ collapsed: !collapsed })}>
                                {this.state.collapsed ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                            </div>
                        </div>
                    </div>

                    {!collapsed?<div> 
                    <div class={styles.body}>
                        <div class={styles.description}>Download a range of products from the NIH's Dietary Supplement Label Database by inputting the first and last product ID in the range looking to be downloaded.</div>
                        <hr />
                        <form>
                            <InputLabel>
                                Enter the first product ID in the range of products you would like to download:
                            </InputLabel>
                            <TextField
                                fullWidth margin="dense"
                                variant="outlined"
                                onChange={(e) => this.update("firstID", e.target.value)}
                            />
                            <InputLabel>
                                Enter the last product ID in the range of products you would like to download:
                            </InputLabel>
                            <TextField
                                fullWidth margin="dense"
                                variant="outlined"
                                onChange={(e) => this.update("secondID", e.target.value)}
                            />
                            <div class={styles.note}>Note: The current DSLD API key is limited to 18,000 calls per hour. To download a single product, enter the same product ID for both fields.</div>
                        </form>
                        <div class={styles.submit} onClick={() => this.submit()}>
                            Download Products
                        </div>
                        <div class={err ? styles.error : styles.success}>
                            {err ? err : this.state.success}
                        </div>
                    </div>
                    </div> : null}
                </div >
            </div >
        )
    }

}

/**
 * @param id of product to be inserted
 * product controller, which is called by post route below, uses these variables from body
 * insert_one(
 *  req.body.dsld_id, 
 *  req.body.product_name, 
 *  req.body.upc, 
 *  req.body.purchase_ref, 
 *  req.body.nutritional_data, 
 *  req.body.serving_size, 
 *  req.body.serving_type
 * )
 */
async function insertProduct(id) {//insert product by dsld product id
    return axios.get(`https://api.ods.od.nih.gov/dsld/v8/label/${id}`, { params: { api_key: "zd2CqC86O1SluAkOBkcI89LWdLbu00dVtQAge4el" } })//DSLD Api call using designated api key (Current limit: 18,000 per hour per ip address)
        .then(response => {

            //exits function if no product is found in DSLD
            if (response.data.length == 0) {
                return
            }

            let arr = response.data['servingSize'][0]['size'].split(' ')//splits serving size and type
            let nutritional_data = '{'//resets nutritional data variable
            let name_description = ''//resets name description
            let nutrition_count = response.data['dietarySupplementsFacts'][0]['ingredients'].length//stores count of ingredients in product

            for (let i = 0; i < nutrition_count; i++) {//reformats ingredient data to string object to be put inside NutraData database
                if (response.data['dietarySupplementsFacts'][0]['ingredients'][i]['childInfo'].length > 0) {//reformats ingredient name description next to name (if present)
                    name_description = ' (' + response.data['dietarySupplementsFacts'][0]['ingredients'][i]['childInfo'][0]['prefix'] + response.data['dietarySupplementsFacts'][0]['ingredients'][i]['childInfo'][0]['name'] + ')'
                }
                nutritional_data += '"' + response.data['dietarySupplementsFacts'][0]['ingredients'][i]['name'] + name_description + '":{"amount":"' + response.data['dietarySupplementsFacts'][0]['ingredients'][i]['data']['sfbQuantityQuantity'] + '","unit":"' + response.data['dietarySupplementsFacts'][0]['ingredients'][i]['data']['unitName'] + '"}'
                //Adds comma after every ingredient but last
                if (i < nutrition_count - 1 && nutrition_count > 1) {
                    nutritional_data += ","
                }
                //resets name_description for every ingredient stored nutritional_data
                name_description = ''
            }
            nutritional_data += '}'

            //Creates variable to hold Contact info
            let ref = ''
            let count = 1
            let address = null
            for (let i = 0; i < response.data['contacts'].length; i++) {//reformats contact information
                if (response.data['contacts'][i]['zip'] !== null) {//if the contact has a street address, it will be added to the contact list
                    if (response.data['contacts'][i]['streetAddress'] !== null) {
                        address = response.data['contacts'][i]['streetAddress'] + ', '
                    }
                    else { address = '' }
                    ref += 'Contact ' + count++ + ': '
                        + response.data['contacts'][i]['name'] + ', '
                        + address
                        + response.data['contacts'][i]['city'] + ', '
                        + response.data['contacts'][i]['state'] + ' '
                        + response.data['contacts'][i]['zip'] + ' \n'
                    address = null
                }
            }

            //converts to format of a javascript stringified array
            let otherIngredientsTemp = '["' + response.data['dietarySupplementsFacts'][0]['otheringredients']['text'].replaceAll(', ', '","') + '"]'
            //converts to format of a postgres array constructor
            otherIngredientsTemp = 'ARRAY' + otherIngredientsTemp.replaceAll("'", "''").replaceAll('["', "['").replaceAll('"]', "']").replaceAll('","', "','")

            let body = {//body object created, product info added
                dsld_id: id,
                product_name: response.data['productName'],
                upc: response.data['sku'],
                purchase_ref: ref,
                nutritional_data: nutritional_data,
                other_ingredients: otherIngredientsTemp,
                serving_size: arr[0],
                serving_type: arr[1],
            }


            return axios.post(`/api/product/insert/${id}`, body).then(res => {//insert product into NutraData database, sending body containing product information
                if (res.status === 200) {
                    return true
                }
            }).catch(err => {
                 if(err.response.data.status === 'Duplicate upc present') {

                    err.response.data.dsld_date = new Date(response.data['events'][0]['date'])
                    err.response.data.upc = response.data['sku']
                //     console.log("Duplicate prod")

                //     const dsld_date = new Date(req.body.entry_date)
                //     let nutradata_date //this will not store the date the product was entered in nutradata, but rather the date the product stored in nutradata was entered into the dsld. Using this we can compare dsld entry dates
                //     //const product_by_upc = await product_accessor.get_one_upc(req.body.upc)
                //     const prod = await axios.get(`/api/product/${id}`).then(res => {})
                //     const product_id = product_by_upc['dsldId']
                //     await axios.get(`https://api.ods.od.nih.gov/dsld/v8/label/${product_id}`, { params: { api_key: "zd2CqC86O1SluAkOBkcI89LWdLbu00dVtQAge4el" } })//DSLD Api call using designated api key (Current limit: 18,000 per hour per ip address)
                //     .then(response => {
                //         nutradata_date = response.data['events'][0]['date']
                //     })

                //     //const nutradata_date = new Date(product_by_upc['created_at'])
                //     console.log('Dsld entry date: ' + dsld_date)
                //     console.log('Nutradata entry date: ' + nutradata_date)

                }
                return err.response.data
            })
        })
}

// async validateEntryDate(id) {

// }

async function getDSLDEntryDate(id) {//insert product by dsld product id
    return axios.get(`https://api.ods.od.nih.gov/dsld/v8/label/${id}`, { params: { api_key: "zd2CqC86O1SluAkOBkcI89LWdLbu00dVtQAge4el" } })//DSLD Api call using designated api key (Current limit: 18,000 per hour per ip address)
        .then(response => {
            return response.data['events'][0]['date']
        })
}

async function setDuplicateProduct(product_id) {
    return axios.post(`/api/product/set/duplicate/${product_id}`)
}

async function replaceProduct(new_id, old_id) {
    return axios.delete(`/api/product/remove/${old_id}`).then(response => {
        insertProduct(new_id).then(response => {
            setDuplicateProduct(new_id)
        })
    })
}

/**
 * 
 * @param {string} id of product being retrieved
 * @returns promise that data will be returned
 */
 async function getProductUPC(upc) {

    const body = {
        upc: upc
    }

	return axios.post(`/api/product/get/upc`, body).then(res => {

		const productInfo = {
			dsld_id: res.data.status.dsld_id,
		}
		return productInfo

	}).catch(err => {
		console.log("Error searching for product")
	})
}

export default DownloadProduct;


/*
<div class={styles.submit} onClick={() => {
    for (let i = 18000; i < 35000; i++)
        callAPI(i)
}}>
    Update Database
</div>

<div class={styles.submit} onClick={() => {
                    insertProduct(10009)
                }}>
                    Insert Product
</div>

*/

/*async function callAPI(id) {

    return axios.get(`https://api.ods.od.nih.gov/dsld/v8/label/${id}`, { params: { api_key: "zd2CqC86O1SluAkOBkcI89LWdLbu00dVtQAge4el" } })
        .then(response => {
            let arr = response.data['servingSize'][0]['size'].split(' ')
            let size = arr[0]
            let type = arr[1]

            let body = { serving_size: size, serving_type: type }

            return axios.post(`/api/product/${id}`, body).then(res => {
                if (res.status === 200) {
                    return true
                }
            }).catch(err => {
                return err.response.statusText
            })
        })
}*/